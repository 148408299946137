import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Image, Banner } from "../Components";
import { useScreenSize } from "../Hooks";

import IMG1 from "../Assets/Prendas/1.webp";
import IMG2 from "../Assets/Prendas/2.webp";
import IMG3 from "../Assets/Prendas/3.webp";
import IMG4 from "../Assets/Prendas/4.webp";
import IMG5 from "../Assets/Prendas/5.webp";
import IMG6 from "../Assets/Prendas/6.webp";

const prenda = [
  {
    id: 1,
    title: "Sombrero y pantalón",
    description: "Viste a la moda, viste con estilo western",
    image: IMG1,
  },
  {
    id: 2,
    title: "Pantalon de mezclilla",
    description: "Pantalon vaquero de mezclilla",
    image: IMG2,
  },
  {
    id: 3,
    title: "Camisa y sombrero",
    description: "Penetra en el mundo western",
    image: IMG3,
  },
  {
    id: 4,
    title: "Sombrero",
    description: "Agrega un sombrero a tu estilo",
    image: IMG4,
  },
  {
    id: 5,
    title: "Sombrero",
    description: "No solo es un sombrero, es un estilo de vida",

    image: IMG5,
  },
  {
    id: 6,
    title: "Outfit completo",
    description: "Viste con estilo western",
    image: IMG6,
  },
];

export const FeaturedCollection = () => {
  const [images, setImages] = useState(IMG1);

  const { width } = useScreenSize();

  const handleChangeImg = (e, id) => {
    e.preventDefault();

    const product = prenda.find((item) => item.id === id);
    setImages(product.image);
  };

  return (
    <div className=" mt-2 mb-2 ">
      {width > 768 ? (
        <Desktop
          prenda={prenda}
          handleChangeImg={handleChangeImg}
          images={images}
        />
      ) : (
        <Mobile
          prenda={prenda}
          handleChangeImg={handleChangeImg}
          images={images}
        />
      )}
    </div>
  );
};

const Desktop = ({ prenda, handleChangeImg = () => {}, images }) => (
  <>
    <div className="mx-5">
      <h2> Prendas a la moda </h2>
    </div>

    <div className="row h-100 justify-content-center">
      <div className="col-12 col-md-5">
        <div className="d-flex flex-column justify-content-around h-100">
          {prenda.map((item) => (
            <div key={item.id} className="row">
              <button
                className="btn p-2"
                onClick={(e) => handleChangeImg(e, item.id)}
              >
                <h4>
                  {" "}
                  <strong> {item.title} </strong>{" "}
                </h4>
                <p> {item.description} </p>
              </button>
              <hr />
            </div>
          ))}
        </div>
      </div>

      <div className="col-12 col-md-5 h-100">
        <div className="row justify-content-center w-100 h-100 d-flex justify-content-center align-content-center">
          <NavLink
            to="#"
            className="text-decoration-none text-black d-flex justify-content-center "
            style={{ height: "85%" }}
          >
            <Image
              src={images}
              alt="imagen"
              className="img-fluid m-auto h-100 "
              style={{ objectFit: "cover" }}
            />
          </NavLink>
        </div>
      </div>
    </div>
  </>
);

const Mobile = ({ prenda, handleChangeImg = () => {}, images }) => (
  <>
    <div className="mx-5">
      <h2> Prendas a la moda </h2>
    </div>

    <div className="row h-100 justify-content-center m-auto align-content-center">
      <Banner
        contentArray={prenda.map((item) => (
          <div
            class="card text-bg-dark p-0"
            style={{ border: "none" }}
            key={item.id}
            onClick={handleChangeImg}
          >
            
            <Image
              src={item.image}
              className="card-img bg-dark  opacity-75"
              alt={item.title}
            />
            
            <div className="card-img-overlay d-flex flex-column justify-content-center ">
              <div className="">
                <h3 className="card-title text-center text-white">{item.title}</h3>
                <p className="card-text text-center text-white">
                  {item.description}{" "}
                </p>
              </div>
            </div>
          </div>
        ))}
        className="w-100 d-flex justify-content-center m-auto align-content-center align-items-center"
        slidesPerView={1}
        autoplay={true}
      />
    </div>
  </>
);
