

export const CustomGrid = ({arrayContent=[]}) => {
  
    return (
        <div className='row'>
            {/* <div className='mb-4 col col-12 col-sm-6 col-lg-4 col-xl-4'> */}
            {
                arrayContent?.map( (value, index) => (
                    <div className='mb-4 col ' key={index}>
                        {value}
                    </div>
                ) )
            }
        </div>
    )
}
