
import IMG1 from '../Assets/AcordionImgs/4.png';
import IMG2 from '../Assets/AcordionImgs/5.png';
import IMG3 from '../Assets/AcordionImgs/6.png';
import { useNavigate } from "react-router-dom";


const images = [
    {
        id: 1,
        src: IMG1,
        alt: "Imagen 1",
        text: "Sin sombrero no hay vaquero... ¡Vamos a conseguir el nuestro!",
        button: "Ir a comprar",
        to: "/seccion/sombreros",
    },
    {
        id: 2,
        src: IMG2,
        alt: "Imagen 2",
        text: "Con el rodeo a la vuelta de la esquina, ¡es hora de buscar la vestimenta perfecta!",
        button: "Ir a comprar",
        to: "/seccion/hombre",
    },
    {
        id: 3,
        src: IMG3,
        alt: "Imagen 3",
        text: "¡Es hora de lucir como todo un cowboy! Vayamos a comprar el look perfecto.",
        button: "Ir a comprar",
        to: "/seccion/mujer",
    },
];

export const AcordionImgs = () => {

    const navigate = useNavigate();

    const handleOnClick = (to) => {
        navigate(to, { replace: true });
    };

    return (
        <div className="container_acordion_img">
            
            {
                images.map((image) => (
                    <div
                        key={image.id} 
                        id={image.id} 
                        className="section_acordion_img" 
                        style={{
                            backgroundImage: `url(${image.src})`, // Ajuste correcto
                        }}
                    >
                        <div className="container content">
                            <div className="row">
                                <h3>{image.text}</h3>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-primary " onClick={()=>handleOnClick(image.to)}>
                                    {image.button}
                                </button>
                            </div>
                        </div>
                        <div className="overlay"></div>
                    </div>
                ))
            }
            
        </div>
    );
};
