import {useEffect, useState} from 'react'
import { PaymentContext } from './PaymentContext'
import { jwtDecode } from 'jwt-decode'
import { requestGet } from '../../Helpers'
import { getOrder } from '../../Components/Cards/helpers'

export const PaymentProvider = ({children}) => {
    
    const [payment, setPayment] = useState({
        total: 0,
        iva: 0,
        totalProducts: 0,
        productsList: [],
        order: ''
    })
    
    
    useEffect(() => {

        try {

            getStore()
            .then((data) => {
                setPayment({
                    total: data?.products?.reduce(getSum, 0),
                    iva: 10,
                    totalProducts: data?.products?.length,
                    productsList: data?.products,
                    order: data?.orderData?.results[0]
                });
            })
        

        } catch (error) {
            console.error(error);
        }  

    }, [])

    const getSum = (total, item) => {
        item = Number(item.price)
        return total + item
    }

    // Función auxiliar para transformar los datos del producto
    const transformProductData = (item, order_id) => {
        const product = {
            id: item?.category?.product_uuid,
            title: item?.category?.product_name,
            price: item?.price,
            image: item?.category?.product_image?.[0]?.image || '',
            color: '',
            size: '',
            amount: item?.quantity,
            amount_max: item?.category?.stock,
            order: order_id
        };

        // Asignar color y talla según las categorías
        item?.category?.category?.forEach((category) => {
            if (category.category === "Colores") {
                product.color = category?.name;
            } else if (category.category === "Tallas") {
                product.size = category?.name;
            }
        });

        return product;
    };

     // Función para obtener los productos del carrito de compra cuando se monta el componente
     const getStore = async () => {
        try {
            // Obtener el token
            const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));
            if (!token) throw new Error("No se encontró el token");
    
            const decoded = jwtDecode(token);
            if (!decoded?.user_id) throw new Error("Token inválido");
    
            // Obtener la orden del usuario
            const orderData = await getOrder(decoded.user_id);
            const order_id = orderData?.results?.[0]?.uuid;
            if (!order_id) return; // No hay orden activa
    
            // Obtener los productos del carrito
            const orderItemsData = await requestGet(`order_items/`, { order: order_id });
            const products = orderItemsData?.results?.map((item) =>
                transformProductData(item, order_id)
            );

            // Aquí puedes manejar los productos obtenidos (ejemplo: actualizarlos en el estado)
            console.log(products);
            return {
                order_id,
                products,
                orderData,
            }
    
        } catch (error) {
            console.error("Error al obtener los datos de la tienda:", error.message);
        }
    };

  
    return (
        <PaymentContext.Provider 
            value={{
                payment,
                setPayment
             }}
        >
            {children}
        </PaymentContext.Provider>
    )
}
