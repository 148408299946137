
import { useEffect, useState } from 'react'
import { prefetchData } from '../../Helpers'

export const SelectMunicipios = ({valueSeletedState = null, valueSelected = null, estadoId, onChange = () => {}}) => {

    const [municipios, setMunicipios] = useState([])

    useEffect(() => {
        try {
            if (estadoId || valueSeletedState) {
                prefetchData('municipios/', { "estado": estadoId || valueSeletedState })
                    .then( (data)=> {
                        setMunicipios( () => data )
                    })
            }
        } catch (error) {
            console.error(error);
        }
    }, [estadoId, valueSelected, valueSeletedState])
  
    return (
        <div>
            <select
                className="form-select" 
                aria-label="Default select example" 
                name='city'
                onChange={(e)=>onChange(e)}
            >
                <option disabled selected>Selecciona un municipio</option>
                {
                    municipios.map((municipio) => (
                        <option key={municipio.id} selected={municipio.id == valueSelected} value={JSON.stringify(municipio)}>
                            {municipio.nombre}
                        </option>
                    ))
                }
            </select>
        </div>
    )
}
