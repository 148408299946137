import { useNavigate } from 'react-router-dom';
import { Image } from '../../../Components';
import IMGLOGO from '../../../Assets/img/logoRopa.png';


export const CardProduct = ({id, image, title, price, color, size}) => {

    const navigate = useNavigate()

    const handleWriteReview = () => {
        navigate('/profile/my_shopping/view/write_review/' + id)
    }

    return (
        <div className="card shadow-none border border-1 p-0 mb-3" style={{ minHeight: "120px" }} tabIndex={id}>
            <div className="row ">
                <div
                    className="col-4 d-flex justify-content-center align-content-center"
                    style={{ height: "120px" }}
                >
                    <Image
                        src={image || IMGLOGO}
                        alt="Producto"
                        style={{ maxWidth: "100%", height: "100%" }}
                        className="img-fluid rounded-start"
                    />
                </div>
                <div className="col-8">
                    <div className="card-body">
                        <h5 className="card-title m-0 p-0" style={{ fontSize: "15px" }}>
                            {title}
                        </h5>
                        
                        <div className="d-flex flex-row justify-content-around ">
                            <p className="card-text m-0 p-0" style={{ fontSize: "13px" }}>
                                <small className="text-muted">${price}</small>
                            </p>
                            <p className="card-text m-0 p-0" style={{ fontSize: "13px" }}>
                                <small className="text-muted">Color: {color}</small>
                            </p>
                            <p className="card-text m-0 p-0" style={{ fontSize: "13px" }}>
                                <small className="text-muted">Talla: {size}</small>
                            </p>
                        </div>
                        <div className="card-footer d-flex justify-content-end ">
                            <div className={`p-0 m-0 d-flex flex-row justify-content-around align-items-center mt-1`}>
                                <div className="d-flex flex-column">
                                    
                                </div>
                                <button className={"btn btn-link "} onClick={handleWriteReview} >
                                    Escribir una opinión sobre el producto
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    )
}
