
/**
 * Reducer para manejar el estado de los filtros seleccionados.
 */
export const filtersReducer = (state, action) => {
    switch (action.type) {
      case "ADD_FILTER":
        return { ...state, [action.name]: action.value };
      case "REMOVE_FILTER":
        const newState = { ...state };
        delete newState[action.name];
        return newState;
      case "RESET_FILTERS":
        return {};
      default:
        return state;
    }
};
  