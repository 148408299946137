import { NavLink } from "react-router-dom";
import logo from "../Assets/img/logoRopa.png";

import { FaTiktok, FaWhatsapp, FaFacebook, FaInstagram } from "react-icons/fa";

export const Footer = () => {
  return (
    <div className="container" style={{ marginTop: 110, maxWidth: "97vw" }}>
      <footer className="row border-top pt-4 w-100">
        <div className="col-3 mb-3 ">
          <NavLink
            to="/"
            className="d-flex align-items-center mb-3 link-dark text-decoration-none"
          >
            <img srcSet={logo} alt="" className="img-fluid rounded-circle" />
          </NavLink>
        </div>

        {/* <div className="col mb-3"></div> */}

        <div className="col-9 mb-3">
          {/* iconos */}
          <section className="trial-block w-100" id="ContactUs">
            <div className="">
              <div className="text-center m-0">
                <span className="badge badge-info">Siguenos a través de</span>
                <h2 className="">Redes sociales</h2>
                <span className=" line border border-1 d-flex mt-3"></span>
              </div>
              <div className="social-overlap process-scetion ">
                <div className="container">
                  <div className="d-flex justify-content-center">
                    <div className="col-md-10">
                      <div className="social-bar">
                        <div className="social-icons mb-3 iconpad text-center">
                          <NavLink
                            to="https://www.facebook.com/profile.php?id=61567688691512&mibextid=kFxxJD"
                            target="_blank"
                            className="slider-nav-item"
                          >
                            <FaFacebook className="" />
                          </NavLink>
                          <NavLink
                            to="https://www.instagram.com/el_tordillo77?igsh=MTd3OWFkeXF5YmJodg%3D%3D&utm_source=qr"
                            target="_blank"
                            className="slider-nav-item"
                          >
                            <FaInstagram />
                          </NavLink>
                          <NavLink
                            to="https://www.tiktok.com/@el_vaquero777"
                            target="_blank"
                            className="slider-nav-item"
                          >
                            <FaTiktok />
                          </NavLink>
                          <NavLink
                            to="https://wa.me/524778767134?text=Hola, estoy interesado en su producto, me gustaría conocer más acerca de"
                            target="_blank"
                            className="slider-nav-item"
                          >
                            <FaWhatsapp />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </footer>
      <p className="text-muted text-center">
        © {new Date().getFullYear()} Cabaña Rodeo Country
      </p>
    </div>
  );
};
