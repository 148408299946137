

export const validateUrl = (url, requiredParams = {}) => {
    const urlObj = new URL(url);

    // Recorremos los parámetros requeridos y comprobamos si están en la URL
    for (const [key, value] of Object.entries(requiredParams)) {
        const paramValue = urlObj.searchParams.get(key);

        if (!paramValue || (value && paramValue != value)) {
            // Si falta el parámetro o su valor no coincide, devolvemos false
            return false;
        }
    }

    // Si todos los parámetros están presentes, retornamos true
    return true;
}