
/*
Política de Cookies de Cabaña Rodeo Country

1. ¿Qué son las Cookies?
Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo (ordenador, tableta o móvil) cuando visitas nuestro sitio web. Estas permiten que el sitio recuerde tus acciones y preferencias (configuraciones) durante un tiempo, para que no tengas que configurarlas cada vez que vuelvas.

2. Tipos de Cookies que Utilizamos

En Cabaña Rodeo Country, utilizamos las siguientes categorías de cookies:
1.	Cookies Esenciales:
Son necesarias para que el sitio web funcione correctamente. Sin estas cookies, algunas funciones como el carrito de compras o el inicio de sesión no pueden operar.
2.	Cookies de Rendimiento:
Recopilan información sobre cómo los usuarios interactúan con nuestro sitio web. Esto nos ayuda a mejorar el diseño y funcionalidad.
3.	Cookies de Funcionalidad:
Permiten que el sitio recuerde tus preferencias para proporcionarte una experiencia más personalizada.
4.	Cookies de Publicidad:
Estas cookies se utilizan para mostrarte anuncios relevantes basados en tus intereses. También nos ayudan a medir la efectividad de nuestras campañas publicitarias.
5.	Cookies de Terceros:
Algunas cookies son colocadas por terceros, como plataformas de análisis (Google Analytics) o herramientas de pago (PayPal).

3. ¿Por Qué Utilizamos Cookies?
Usamos cookies para:
•	Mejorar la funcionalidad del sitio y tu experiencia de usuario.
•	Recordar tus preferencias, como el idioma o los productos en tu carrito.
•	Analizar el tráfico en el sitio y comprender cómo interactúan los usuarios con nuestras páginas.
•	Mostrarte contenido y promociones relevantes.

4. Gestión de las Cookies
Tienes derecho a aceptar o rechazar el uso de cookies en cualquier momento. Aquí te mostramos cómo hacerlo:
1.	Configuración del Navegador:
Puedes configurar tu navegador para bloquear o eliminar cookies. Las opciones varían según el navegador:
•	Google Chrome: Configuración > Privacidad y seguridad > Cookies y otros datos de sitios.
•	Mozilla Firefox: Preferencias > Privacidad y seguridad > Cookies y datos del sitio.
•	Safari: Preferencias > Privacidad > Bloquear cookies.
•	Microsoft Edge: Configuración > Cookies y permisos del sitio.
2.	Panel de Configuración de Cookies (si lo implementas):
En nuestro sitio, puedes gestionar tus preferencias a través del panel de configuración de cookies.
5. Consentimiento
Al visitar nuestro sitio por primera vez, te mostraremos un banner para informarte sobre el uso de cookies. Al continuar navegando o aceptar en el banner, consientes el uso de cookies según esta política.
Puedes cambiar tus preferencias en cualquier momento desde el panel de configuración de cookies.
6. Cookies de Terceros
Usamos herramientas y servicios de terceros que pueden colocar cookies en tu dispositivo, como:
•	Google Analytics: Para analizar el tráfico del sitio y el comportamiento de los usuarios.
•	Google: Para brindarte el inicio de sesión
•	PayPal: Para procesar pagos seguros.
Estos servicios tienen sus propias políticas de privacidad que te recomendamos revisar.
7. Cambios a Esta Política
Podemos actualizar esta Política de Cookies para reflejar cambios en nuestras prácticas o en la legislación aplicable. Revisa esta página periódicamente para estar informado.
8. Contacto
Si tienes preguntas o inquietudes sobre nuestra política de cookies, contáctanos:
•	Correo: soporte@cabañarodeo.com
•	Teléfono: +52 123 456 7890

 */

import React from 'react'

export const PoliticaCookies = () => {
  
    return (
        <div>
            <h1>Política de Cookies de Cabaña Rodeo Country</h1>
            <p>1. ¿Qué son las Cookies?</p>
            <p>Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo (ordenador, tableta o móvil) cuando visitas nuestro sitio web. Estas permiten que el sitio recuerde tus acciones y preferencias (configuraciones) durante un tiempo, para que no tengas que configurarlas cada vez que vuelvas.</p>
            <p>2. Tipos de Cookies que Utilizamos</p>
            <p>En Cabaña Rodeo Country, utilizamos las siguientes categorías de cookies:</p>
            <p>1.	Cookies Esenciales:</p>
            <p>Son necesarias para que el sitio web funcione correctamente. Sin estas cookies, algunas funciones como el carrito de compras o el inicio de sesión no pueden operar.</p>
            <p>2.	Cookies de Rendimiento:</p>
            <p>Recopilan información sobre cómo los usuarios interactúan con nuestro sitio web. Esto nos ayuda a mejorar el diseño y funcionalidad.</p>
            <p>3.	Cookies de Funcionalidad:</p>
            <p>Permiten que el sitio recuerde tus preferencias para proporcionarte una experiencia más personalizada.</p>
            <p>4.	Cookies de Publicidad:</p>
            <p>Estas cookies se utilizan para mostrarte anuncios relevantes basados en tus intereses. También nos ayudan a medir la efectividad de nuestras campañas publicitarias.</p>
            <p>5.	Cookies de Terceros:</p>
            <p>Algunas cookies son colocadas por terceros, como plataformas de análisis (Google Analytics) o herramientas de pago (PayPal).</p>
            <p>3. ¿Por Qué Utilizamos Cookies?</p>
            <p>Usamos cookies para:</p>
            <p>•	Mejorar la funcionalidad del sitio y tu experiencia de usuario.</p>
            <p>•	Recordar tus preferencias, como el idioma o los productos en tu carrito.</p>
            <p>•	Analizar el tráfico en el sitio y comprender cómo interactúan los usuarios con nuestras páginas.</p>
            <p>•	Mostrarte contenido y promociones relevantes.</p>
            <p>4. Gestión de las Cookies</p>
            <p>Tienes derecho a aceptar o rechazar el uso de cookies en cualquier momento. Aquí te mostramos cómo hacerlo:</p>
            <p>1.	Configuración del Navegador:</p>
            <p>Puedes configurar tu navegador para bloquear o eliminar cookies. Las opciones varían según el navegador:</p>
            <p>•	Google Chrome: Configuración {'>'} Privacidad y seguridad {'>'} Cookies y otros datos de sitios.</p>
            <p>•	Mozilla Firefox: Preferencias {'>'} Privacidad y seguridad {'>'} Cookies y datos del sitio.</p>
            <p>•	Safari: Preferencias {'>'} Privacidad {'>'} Bloquear cookies.</p>
            <p>•	Microsoft Edge: Configuración {'>'} Cookies y permisos del sitio.</p>
            <p>2.	Panel de Configuración de Cookies (si lo implementas):</p>
            <p>En nuestro sitio, puedes gestionar tus preferencias a través del panel de configuración de cookies.</p>
            <p>5. Consentimiento</p>
            <p>Al visitar nuestro sitio por primera vez, te mostraremos un banner para informarte sobre el uso de cookies. Al continuar navegando o aceptar en el banner, consientes el uso de cookies según esta política.</p>
            <p>Puedes cambiar tus preferencias en cualquier momento desde el panel de configuración de cookies.</p>
            <p>6. Cookies de Terceros</p>
            <p>Usamos herramientas y servicios de terceros que pueden colocar cookies en tu dispositivo, como:</p>
            <p>•	Google Analytics: Para analizar el tráfico del sitio y el comportamiento de los usuarios.</p>
            <p>•	Google: Para brindarte el inicio de sesión</p>
            <p>•	PayPal: Para procesar pagos seguros.</p>
            <p>Estos servicios tienen sus propias políticas de privacidad que te recomendamos revisar.</p>
            <p>7. Cambios a Esta Política</p>
            <p>Podemos actualizar esta Política de Cookies para reflejar cambios en nuestras prácticas o en la legislación aplicable. Revisa esta página periódicamente para estar informado.</p>
            <p>8. Contacto</p>
            <p>Si tienes preguntas o inquietudes sobre nuestra política de cookies, contáctanos:</p>
            <p>•	Correo: soporte@cabañarodeo.com</p>
            <p>•	Teléfono: +52 462 480 4805</p>
        </div>
    )
}
