import { useEffect, useState } from "react";
import { prefetchData } from "../../Helpers";
import { jwtDecode } from "jwt-decode";
import { CardAddress } from "./Components";
import { useNavigate } from "react-router-dom";

export const Address = () => {
  const navigate = useNavigate();

  const [address, setAddress] = useState([]);

  useEffect(() => {
    getAddres();

    return () => {
      setAddress([]);
    }
  }, []);

  const handleNavigateToAddAddress = () => {
    navigate("/profile/address/add");
  };

  const getAddres = () => {
    try {
      const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));

      const decoded = jwtDecode(token);

      prefetchData(`shipping_addresses/`, {"user": decoded?.user_id})
        .then(
          (data) => {
            setAddress(() => data?.results);
          }
        );
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="container">
      <h2 className="text-start text-primary"> Direcciones de envío </h2>

      <div className="row">
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-primary"
            onClick={handleNavigateToAddAddress}
          >
            Agregar dirección
          </button>
        </div>
      </div>

      <div className="row">
          {
            address?.map((item, index) => (
              <div key={index} className="col-12 col-md-4">
                <CardAddress
                  id={item.id}
                  name={item.name}
                  address={item.address}
                  state={item?.state_city?.nombre}
                  city={item?.city_municipaly?.nombre}
                  zipcode={item.zipcode}
                  phone={item.phone}
                  instructions={item.instructions || "..."}
                  updated_at={item.updated_at}
                  ruta={`/profile/address/${item.id}/update`}
                  deleteAddres={true}
                />
              </div>
            ))
          }
      </div>

      <div className="row"></div>
    </div>
  );
};
