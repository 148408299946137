

export const Configuration = () => {



    return (
        <div>
            <h1> Configuración </h1>
        </div>
    )
}
