import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from 'query-string';
import { LoginPage } from "./LoginPage";
import { Register } from "./Register";

export const MyAccount = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { q = '' } = queryString.parse(location.search);

  useEffect(() => {
    try {
      
      if (q === 'register') {
        navigate('?q=register');
      } else {
        navigate('?q=login');
      }

    } catch (error) {
      console.log(error);
    }
  }, [q]);

  const handleRedirect = (path) => {
    navigate(path);
  }
  
  return (
    <div className="" style={{ width: "99vw", height: "100vh" }}>
      <div className="row m-auto h-100">
        <div className="col-10 m-auto">
          <ul
            className="nav nav-pills nav-pills-account justify-content-center pb-0"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className={"nav-link nav-link-account ".concat(q === 'register' ? '' : 'active')}
                id="pills-login-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-login"
                type="button"
                role="tab"
                aria-controls="pills-login"
                aria-selected={q === 'register' ? 'false' : 'true'}
                onClick={() => handleRedirect('?q=login')}
              >
                Iniciar Sesión
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={"nav-link nav-link-account ".concat(q === 'register' ? 'active' : '')}
                id="pills-register-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-register"
                type="button"
                role="tab"
                aria-controls="pills-register"
                aria-selected={q === 'register' ? 'true' : 'false'}
                onClick={() => handleRedirect('?q=register')}
              >
                Crear cuenta
              </button>
            </li>
          </ul>
          <div className="tab-content mt-0 pt-0" id="pills-tabContent">
            <div
              className={"tab-pane fade ".concat(q === 'register' ? '' : 'show active')}
              id="pills-login"
              role="tabpanel"
              aria-labelledby="pills-login-tab"
              tabIndex="0"
            >
              <LoginPage />
            </div>
            <div
              className={"tab-pane fade".concat(q === 'register' ? 'show active' : '')}
              id="pills-register"
              role="tabpanel"
              aria-labelledby="pills-register-tab"
              tabIndex="0"
            >
              <Register />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
