import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import ImageGallery from 'react-image-gallery'
import { useRequestGet } from '../Hooks'
import { BtnAddCar, ReviewsByProduct, BtnFav, Characteristics, ContentShowMore } from '../Components'
import { BannersCards } from '../Section/BannersCards'
import { SpinnerLoading } from '../Components/SpinnerLoading'
import IMGLOGO from '../Assets/img/logoRopa.png';


export const ViewProduct = () => {

    const {id} = useParams()

    const {data, isLoading} = useRequestGet(`products/`, {"uuid":id})

    const [product, setProduct] = useState({})
    const [images, setImages] = useState([])

    useEffect(() => {
        try {
            if (data) {
                setProduct( () => data?.results[0] )
            }
        } catch (error) {
            console.log(error);
        }
    }, [id, data])

    useMemo(() => {
        if (product?.images) {
            setImages( product.images.map(item => ({ original: item.image, thumbnail: item.image }) ) )
        } else {
            setImages(
                [
                    {
                      original: IMGLOGO,
                      thumbnail: IMGLOGO,
                    },
                ]
            )
        }
    }, [product])
    
    //de la card ---> box-shadow: '20px 20px 100px #dedede, -20px -20px 400px #ffffff' 
    return (
        <div className='container '>
            <div className='row'>
                <div className='col-12'>
                    <div className='card' style={{ boxShadow: 'none' }}> 
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='card-img'>
                                    {
                                        isLoading
                                        ? <SpinnerLoading />
                                        : (
                                            <ImageGallery 
                                                additionalClass="img-fluid"
                                                items={images} 
                                                thumbnailPosition = 'left'
                                                showBullets = {false}
                                                showPlayButton = {false}
                                                lazyLoad = {true}
                                            />
                                        )
                                    }
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <h2 className='text-center'>{product?.name}</h2>
                                <h3 className='text-end mt-3 mb-2'><i className=''>${product?.price}</i></h3>
                                <p className='mt-3'>{product?.description}</p>

                                <Characteristics category={product?.specific_category} />

                            
                                <div className='d-flex justify-content-between'>
                                    <BtnAddCar 
                                        id={product?.uuid}
                                        title={product?.name}
                                        description={product?.description}
                                        price={product?.price}
                                        image={product?.image}

                                        text_aditional='Agregar al carrito'
                                    />
                                    <BtnFav 
                                        id={product?.uuid}
                                        title={product?.name}
                                        description={product?.description}
                                        price={product?.price}
                                        image={product?.image}
                                        text_aditional='Agregar a favoritos'
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className='card-body' style={{ marginTop: '55px', borderRadius: '25px', boxShadow: '20px 20px 100px #dedede, -20px -20px 400px #ffffff' }}>
                            
                            <br className='border p-1 border-1' />

                            <div className='mt-3'>
                                <h3>Detalles del producto</h3>
                                <div className='d-flex flex-column'>
                                    <p> 
                                        <ContentShowMore
                                            text={product?.detail?.description} 
                                        />
                                    </p>

                                    <div className='d-flex flex-column'>
                                        <p>Fabricante: {product?.detail?.fabricator}</p>
                                        <p>Modelo: {product?.detail?.modelo}</p>
                                    </div>

                                    <h6>Especificaciones</h6>
                                    <p
                                        dangerouslySetInnerHTML={{ __html: product?.detail?.details }}
                                    />
                                    {/* <p>{ product?.detail?.details }</p> */}
                                </div>
                            </div>

                            <br />

                            <div className=' mt-3'>
                                <div className='card-body'>
                                    <h3 className='' >Opiniones del producto</h3>
                                    <ReviewsByProduct id={product?.uuid} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-3'>
                    
                </div>
            </div>
            <div className='mt-4 w-100 d-flex flex-column h-100' style={{ maxWidth: '100%', width: '100%' }}>
                <div className='w-100'>
                    <h3 className='text-center p-2'>Productos relacionados</h3>
                </div>
                <div className='d-flex flex-wrap'>
                    <BannersCards filters='?alternar=True&page_size=6' />
                </div>
            </div>
        </div>
    )
}
