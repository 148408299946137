import CryptoJS from "crypto-js";


// Clave de encriptación (puedes usar algo más seguro)
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY_PARAMS;

// Desencriptar parámetros
export const decryptParams = (cipherText) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY);
    const jsonString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(jsonString);
};
  