
import { Image } from "../LazyLoad";
import { deleteItem } from "./helpers";
import { CiTrash } from "react-icons/ci";
import IMGLOGO from '../../Assets/img/logoRopa.png';

export const CardFavorite = ({ id, title, image, price }) => {
  // const { store, setStore } = useContext(StoreContextMain);

  const removeItem = () => {
    deleteItem(id);
    // const newStore = store.filter((item) => item.id !== id);
    // setStore(newStore);
  };

  return (
    <div className="card p-0 mb-3" style={{ maxHeight: "120px" }} tabIndex={id}>
      <div className="row ">
        <div
          className="col-4 d-flex justify-content-center align-content-center"
          style={{ height: "110px" }}
        >
          <Image 
            src={image || IMGLOGO} 
            alt="Producto"  
            style={{ maxWidth: "100%" }}
            className="img-fluid rounded-start"
          />
        </div>
        <div className="col-8">
          <div className="card-body">
            <h5 className="card-title m-0 p-0" style={{ fontSize: "15px" }}>
              {title}
            </h5>
            <p className="card-text m-0 p-0" style={{ fontSize: "13px" }}>
              <small className="text-muted">{price}</small>
            </p>
            <div className="p-0 m-0 d-flex justify-content-around">
              <button className={"btn btn-sm btn-danger"} onClick={removeItem}>
                <CiTrash className="fs-6" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
