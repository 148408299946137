import React, { useEffect, useState } from 'react'
import { CardOrder } from '../../Components'
import { useNavigate, useParams } from 'react-router-dom'
import { prefetchData, requestGet } from '../../Helpers'
import { useChekingAuth } from '../../Auth'
import { CardProduct } from './Components/CardProduct'

/**
 * Componente para visualizar los productos comprados
 * En este componente se visualizan los productos comprados por el usuario,
 * se puede ver el detalle de la compra y el estado de la misma,
 * además de poder realizar acciones como cancelar la compra,
 * solicitar ver o imprimir el recibo de la compra,
 * ver el estado de la compra, entre otras.
 * @returns 
 */

export const ViewShopping = () => {

    const navigate = useNavigate()

    const {order_id, sale_id} = useParams()

    const { logged } = useChekingAuth();

    const [shopping, setShopping] = useState([])
    const [sale, setSale] = useState([])

    useEffect(() => {

        try {
            if (logged) {
                getSaleItems()
                getViewSale()
            }
        } catch (error) {
            console.log(error)
        }

    }, [logged])


    const handleViewPackageTracking = () => {
        navigate('/profile/my_shopping/view/package_tracking/' + order_id)
    }

    const handleViewRecibo = () => {
        navigate('/profile/my_shopping/view/ticket/' + order_id)
    }

    const getSaleItems = async () => {
        if (order_id === null || order_id === undefined) {
            return setShopping([])
          };
          
        await prefetchData(`order_items/`, {"order": order_id})
        .then((data) => {
            data = data?.results.map((item) => {
            // se obtiene la información del producto
                const product = {
                    id: item?.category?.product_uuid,
                    title: item?.category?.product_name,
                    price: item?.price,
                    image: item?.category?.product_image !== null ? item?.category?.product_image[0]?.image : '',
                    color: '',
                    size: '',
                    amount: item?.quantity,
                    amount_max: item?.category?.stock
                }

                // se obtiene el color y la talla del producto
                item?.category?.category?.forEach( category => {

                if (category.category === "Colores") {
                    product.color = category?.name;
                } else if (category.category === "Tallas") {
                    product.size = category?.name;
                }

            })
            
                return product
            } );

            setShopping(data ?? []);
        
        })

    }

    const getViewSale = async () => {
        try {
            await prefetchData(`view-sales/`, { "order_id": order_id })
            .then((data) => {
                setSale(data?.results[0] || [])
            })
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='container'>

            <div className='col-12'>

                <div className='d-flex flex-column justify-content-around m-3 mb-5'>
                    <h2 className='text-center'>Mi compra</h2>
                    <p className='text-center'>Aquí puedes ver los detalles de tu compra</p>
                </div>

                <div className='row'>
                    <div className='col-12 col-md-8'>
                        {
                            shopping?.map((item, index) => {
                                console.log('item', item)
                                return (
                                    <div>
                                        <CardProduct
                                            key={index}
                                            id={item.id}
                                            title={item.title}
                                            image={item.image}
                                            price={item.price}
                                            color={item.color}
                                            size={item.size}
                                            amount={item.amount}
                                            amount_max={item.amount_max}
                                            enableFeacture={true}
                                        />   
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='col-12 col-md-4'>
                        <div className='card'>

                            <div className='card-body'>
                                <h3 className='text-center'>Detalle de la compra</h3>
                                <div className='row justify-content-between'>
                                    <div className='col-6'>
                                        <p className='card-text'>N° de productos:</p>
                                    </div>
                                    <div className='col-6'>
                                        <p className='card-text'> {shopping?.length} </p>
                                    </div>
                                </div>
                                <div className='row justify-content-between'>
                                    <div className='col-6'>
                                        <p className='card-text'>Total:</p>
                                    </div>
                                    <div className='col-6'>
                                        <p className='card-text'>  ${sale?.total} </p>
                                    </div>
                                </div>
                                <div className='row justify-content-between'>
                                    <div className='col-6'>
                                        <p className='card-text'>Fecha de compra:</p>
                                    </div>
                                    <div className='col-6'>
                                        <p className='card-text'>  { new Date(sale?.created_at).toLocaleDateString("es-ES", {year: "numeric", month: "long", day: "numeric"}) } </p>
                                    </div>
                                </div>
                                <div className='row justify-content-between'>
                                    <div className='col-6'>
                                        <p className='card-text'>Estado de la compra:</p>
                                    </div>
                                    <div className='col-6'>
                                        <p className='card-text'> {sale?.status_paypal} </p>
                                    </div>
                                </div>
                                <div className='row justify-content-between'>
                                    <div className='col-6'>
                                        <p className='card-text'>Método de pago:</p>
                                    </div>
                                    <div className='col-6'>
                                        <p className='card-text'> PayPal </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <hr />

                        <div className='card'>
                            <div className='card-body'>
                                <h3 className='text-center'>Acciones</h3>
                                <div className='d-flex justify-content-around gap-2 flex-wrap'>
                                    <button onClick={()=>{}} className='btn btn-primary'> Cancelar pedido </button>
                                    <button onClick={handleViewPackageTracking} className='btn btn-primary'> Ver seguimiento </button>
                                    <button onClick={handleViewRecibo} className='btn btn-primary'> Ver recibo </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
