import axios from "axios";
import { baseUrl } from "../shared/baseUrl";

export const requestDelete = async (serviceName, enableToken = true) => {
    try {
        // Validar parámetro serviceName
        if (!serviceName) throw new Error("Service name is missing");

        // Obtener y verificar token de autenticación si está habilitado
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
        
        if (enableToken) {
            const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));
            if (!token) throw new Error("User token is missing from localStorage");
            headers.Authorization = `Bearer ${token}`;
        }

        // Realizar la solicitud DELETE con axios
        const response = await axios.delete(baseUrl.concat(serviceName), { headers });

        // Verificar si la respuesta es satisfactoria
        if (response.status === 200 || response.status === 204) {
            return response.data || "Deleted successfully"; // Retornar datos o mensaje de éxito si no hay contenido
        } else {
            throw new Error(`Unexpected status code: ${response.status}`);
        }
    } catch (error) {
        if (error.response) {
            // Errores HTTP
            console.error(`HTTP Error ${error.response.status}: ${error.response.statusText}`);
            throw new Error(`Request failed with status ${error.response.status}`);
        } else if (error.request) {
            // Errores de red
            console.error("Network error: No response received");
            throw new Error("Network error: Please check your connection");
        } else {
            // Otros errores
            console.error("Request error:", error.message);
            throw error;
        }
    }
};
