
export const FilterSearch = () => {


  return (
    <>
      <div 
        className={'custom-search d-none display-md-block ' }
      >
        <select className={""} >
          <option value="0">Buscador</option>
          <option value="1">Mujer</option>
          <option value="2">Hombre</option>
          <option value="3">Niños</option>
        </select>
      </div>
    </>
  );
};


