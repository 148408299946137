import Swal from 'sweetalert2'

// typeIcon = [success, error, ]

export const alertToast = (title, typeIcon = 'success', position='end', timer = 3000) => {

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-'+position,
        showConfirmButton: false,
        timer: timer,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    Toast.fire({
        icon: typeIcon,
        title: title
    })


}