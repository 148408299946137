import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BtnGoBack } from '../Components';
import { jwtDecode } from 'jwt-decode';
import { prefetchData, requestGet, requestPatch, requestPut } from '../../Helpers';
import { CardAddress } from '../../Profile/Address/Components/CardAddress';
import { PaymentContext } from '../Context/PaymentContext';
import { alertError } from '../../Helpers/Alerts';
import { NotFound } from '../../Components/NotFound';

export const PersonalInformation = () => {

    const { payment, setPayment } = useContext(PaymentContext);

    const [myCards, setMyCards] = useState([]);

    const [selectedCard, setSelectedCard] = useState(null);
  
    const navigate = useNavigate();
  
    useEffect(() => {
        const token = localStorage.getItem('user_store_ecommerce');

        if (token) {
          try {
            const decoded = jwtDecode(JSON.parse(token));
            fetchCards(decoded?.user_id);
          } catch (error) {
            console.error('Error decoding token:', error);
          }
        } else {
          console.error('No token found in localStorage');
        }
    }, []);
  
    const fetchCards = async (userId) => {
        try {
            const data = await prefetchData('shipping_addresses/', { user: userId });
            const cards = data || [];
            setMyCards(cards);
            console.log(cards);
            console.log(payment);
      
            if (payment?.order?.shipping_address) {
              const defaultCard = cards?.results.find(
                (item) => item.id === payment.order.shipping_address
              );
              setSelectedCard(defaultCard || null);
              handleCardSelection(defaultCard || null);
            }
        } catch (error) {
            console.error('Error fetching cards:', error);
            setMyCards([]);
        }
    };

    const handleContinue = () => {
        if (!selectedCard) return alertError('Debes seleccionar una dirección de envío');

        if (payment?.order?.shipping_address == selectedCard.id) {
            return navigate('/payment/confirm-purchase');
        }
        
        saveAddress(selectedCard)
            .then((data) => {
                try {
                    if (data) {
                        return navigate('/payment/confirm-purchase');
                    } else { 
                        return alertError('Error al guardar la dirección de envío');
                    }
                } catch (error) {
                    console.error(error);
                }
            })
    }

    const saveAddress = async (address) => {
        console.log(address);
        console.log(payment);
        
        try {
            const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));
            const decode = jwtDecode(token);

            const formData = new FormData();
            formData.append('shipping_address', address.id);
            formData.append('user', decode?.user_id);

            const data = await requestPatch(
                `orders/${payment.order?.uuid}/`,
                formData
            );
            console.log(data);
            return data && typeof data === 'object';

        } catch (error) {
            console.error('Error saving address:', error);
            return false;
        }
    }

    const handleCardSelection = (card) => {
        console.log(card);
        if (selectedCard?.id === card?.id) {
            setSelectedCard(null);
            setPayment((prev) => ({ ...prev, address: null }));
          } else {
            setSelectedCard(card);
            setPayment((prev) => ({ ...prev, address: card }));
          }
    }
    

    return (
        <div className='container mt-3'>
            <div className='row justify-content-center'>
                <div className='container'>
                    <div className='card shadow-none p-0'>
                        <div className='card-header'>
                            <h5 className='card-title'>Direcciones de envío</h5>
                        </div>

                        <div className="card-body ">
                            <div className='row '>
                            {
                                myCards?.results?.length === 0 && (
                                    <div className='col m-4'>
                                        <NotFound 
                                            title='No tienes direcciones de envío'
                                            message='Ir a agregar dirección de envío'
                                            navigate={true}
                                            path='/profile/address/add'
                                            navigateText='Agregar dirección'
                                        />
                                    </div>
                                )
                            }
                            {
                                myCards?.results?.map((item, index) => {
                                    return (
                                        <div className='col' key={index}>
                                            <div className="form-check" key={item.id}>
                                                <input
                                                    className="form-check-input position-absolute m-2"
                                                    style={{ width: '20px', height: '20px' }}
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id={`address-${item.id}`}
                                                    onClick={() => handleCardSelection(item)}
                                                    checked={selectedCard?.id === item.id}
                                                />
                                                <label className="form-check-label" htmlFor={`address-${item.id}`}>
                                                    <CardAddress
                                                        key={item.id}
                                                        name={item.name}
                                                        address={item.address}
                                                        city={item.city}
                                                        state={item.state}
                                                        zipcode={item.zipcode}
                                                        phone={item.phone}
                                                        updated_at={item.updated_at}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            </div>
                        </div>

                        <div className='d-flex justify-content-end p-3'>
                            <BtnGoBack />
                            &nbsp;
                            <button className="btn btn-primary">Guardar</button>
                            &nbsp;                           
                            <button className='btn btn-primary' onClick={handleContinue}>Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
