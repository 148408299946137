import { RoutesRoot } from "./Routes";
import { Header, Footer, HeaderLogin } from "./Includes";
import { NavLink } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import CookieBanner from "./Components/CookieBanner";


function App() {

    return (
        <>
            {/* <HeaderLogin /> */}
            <Header />
            
            <main className="container-fluid" style={{ position: 'relative', top: '80px' }}>
                <RoutesRoot />
            </main>
            <CookieBanner />
            <NavLink 
                className="float-wa" 
                to="https://wa.me/524778767134?text=Hola, estoy interesado en su producto, me gustaría conocer más acerca de"
                target="_blank"
            >
                <FaWhatsapp />

            </NavLink>
            <Footer />
        </>
    );
}

export default App;
