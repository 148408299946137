import React from 'react'

export const CardAdvertisement = ({icon, title, text}) => {
  
    return (
        <div className='card' style={{ width: '230px' }}>
            <div className=' m-2 mt-0' >
                <div
                    className=' rounded-4 d-flex align-items-center justify-content-center'
                    style={{ 
                        width: '42px',
                        height: '42px',
                        border: '1px solid rgba(0,0,0,.3)',
                        
                    }}
                >
                    {icon}
                </div>
            </div>
            <div className=''>
                <h4>
                    {title}
                </h4>
                <p className='pb-0 mb-0'>
                    {text}
                </p>
            </div>
        </div>
    )
}
