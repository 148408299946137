import React from 'react'
import { Route, Routes } from "react-router-dom";
import {
    DetailsPayment,
    PersonalInformation,
    ConfirmPurchase
} from "../View";
import { PaymentProvider } from '../Context/PaymentProvider';
import { PaymentSuccess } from '../View/PaymentSuccess';

  
export const RouterPayment = () => {

    return (
        <PaymentProvider>
            <Routes>
                <Route path="/details-payment" element={<DetailsPayment />} />
                <Route path="/personal-information" element={<PersonalInformation />} />
                {/* <Route path="/purchase-detail" element={<PurchaseDetail />} /> */}
                <Route path="/confirm-purchase" element={<ConfirmPurchase />} />
                <Route path="/payment-success/:params/" element={<PaymentSuccess />} />
            </Routes>
        </PaymentProvider>
    )
}
