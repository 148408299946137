import { useContext, useEffect, useReducer, useMemo } from 'react'
import { useRequestGet } from '../../Hooks'
import { StoreContextMain } from '../../Context/StoreContextMain'
import { filtersReducer } from './filtersReducer';

/**
 * Este componente se encarga de mostrar los filtros de los productos.
 * en base a las categorias y subcategorias de los productos que vienen de la API.
 * @returns {JSX.Element}
 */

export const Filters = () => {

    const { setFilterContext } = useContext(StoreContextMain);

    const [selectedFilters, dispatch] = useReducer(filtersReducer, {});

    const { data : categories } = useRequestGet('category/')

    // Actualizar el contexto cuando cambian los filtros
    useEffect(() => {
        console.log(selectedFilters)
        setFilterContext(selectedFilters);
    }, [selectedFilters, setFilterContext]);

    // Manejo del cambio de filtros
    const handleCheckValue = (e) => {
        const { name, checked, value } = e.target;

        if (checked) {
            dispatch({ type: "ADD_FILTER", name, value });
        } else {
            dispatch({ type: "REMOVE_FILTER", name });
        }
    };

     // Generar dinámicamente las categorías y subcategorías
    const renderFilters = useMemo(() => {
        if (!categories) return null;

        return categories.map((category, index) => {
            if (!category?.category_item?.length) {
                return null;
            }

            return (
                <form key={index} className="filter-form">
                    <h5>{category.name}</h5>
                    <ul className="d-flex flex-column filter-list">
                        {category.category_item.map((sub, subIndex) => (
                        <li key={subIndex} className='list-unstyled' >
                            <label className='checkbox__label'>
                                <span className="checkbox__container">
                                    <input
                                        className='checkbox' 
                                        type='checkbox' 
                                        name={sub.name}
                                        value={sub.name}
                                        checked={!!selectedFilters[sub.name]}
                                        onChange={handleCheckValue}
                                    />
                                    <span className='checkbox__label--text '>{sub.name}</span>
                                </span>
                            </label>
                        </li>
                        ))}
                    </ul>
                </form>
            );
        });
    }, [categories, selectedFilters]);

    return (
        <div 
            className='d-flex flex-column w-100 m-auto' 
            style={{ overflowY: 'auto', overflowX: 'hidden' }}
        >
            {renderFilters}    
        </div>
    )
}
