import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
        staleTime: 1000 * 60 * 5, // 5 minutos antes de que se consideren "obsoletos"
        cacheTime: 1000 * 60 * 10, // 10 minutos en caché
        refetchOnWindowFocus: false, // recargar automáticamente al enfocar la ventana
    },
  },
});