import {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { useForm, useRequestGet } from '../../Hooks'
import { alertError, alertSuccess, createFormData, requestPost } from '../../Helpers'
import { Image } from '../../Components'
import { jwtDecode } from 'jwt-decode'


/**
 * Componente para escribir una opinión sobre un producto
 * En este componente se permite al usuario escribir una opinión sobre un producto
 * que ha comprado previamente.
 * @returns 
 */

export const WriteReview = () => {

    const { id } = useParams()
    
    const [review, setReview] = useState(null)
    const [loading, setLoading] = useState(false)
    
    const { data } = useRequestGet(`rate-product/`, { product: id }, loading)
    
    const {formState, onInputChange} = useForm({
        comment: "",
        rating: 0,
        product: id
    })

    useEffect(() => {

        if (typeof data === 'object') {
            setReview(data?.results[0])

        }

    }, [id, data])

    const handleSubmit = async (e) => {
        e.preventDefault()
        const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));

        const decoded = jwtDecode(token)        
        
        const formData = createFormData({
            ...formState,
            user: decoded.user_id
        })

        await requestPost("reviews/", formData)
        .then( (data) => {
            console.log(data)
            if (typeof data === 'object' && data?.product) {
                setLoading(true)
                return alertSuccess("Opinión enviada correctamente")
            } else {
                return alertError("Error al enviar la opinión")
            }
        })
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h2>Escribe una opinión sobre el producto</h2>
                    
                    <div className="card m-auto mb-3 w-50 shadow-none">
                        <div className="row g-0">
                            <div className="col-md-4">
                                <Image
                                    src={review?.img_main}
                                    alt={review?.name}
                                    className="img-fluid rounded-start"
                                />
                            </div>

                            <div className="col-md-8">
                                <div className="card-body">
                                    <h5 className="card-title">{review?.name}</h5>
                                    <p className='card-text'>{review?.description}</p>
                                    <p className='card-text'>Precio: ${review?.price}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <form onSubmit={handleSubmit}>

                        <div className="mb-3">
                            <label htmlFor="comment" className="form-label">Comentario del producto</label>
                            <textarea className="form-control" id="comment" name="comment" value={formState.comment || review?.review[0]?.comment} disabled={review?.review[0]?.comment} onChange={onInputChange} required />
                        </div>

                        <div className="mb-3">
                            <label htmlFor='rating' className='form-label'>Califica al producto</label>
                            <select className='form-select' id='rating' name='rating' value={formState.rating || review?.review[0]?.rating} disabled={review?.review[0]?.rating} onChange={onInputChange} required>
                                <option value={4}>Selecciona una calificación</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                            </select>
                        </div>

                        <div className='row'>
                            <div className='d-flex justify-content-center'>
                                {
                                    review?.review[0] ? (
                                        <button type='button' className='btn btn-primary' disabled>Ya has enviado tu opinión</button>
                                    ) : (
                                        <button type='submit' className='btn btn-primary'>Enviar opinión</button>
                                    )
                                }
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}
