import {useState, useEffect} from 'react'

export const useScreenSize = () => {
  
    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)
    const [isMobile, setIsMobile] = useState(false)

    const handleResize = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
        // Si el ancho de la pantalla es menor a 768px, entonces es un dispositivo móvil
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect( () => {
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    } )

    return {
        width,
        height,
        isMobile
    }
}
