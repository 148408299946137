import { useState, useEffect, useRef } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { createPortal } from 'react-dom';

/**
 * Este componente se encarga de mostrar un OffCanvas en la pantalla.
 * @param {Object} iconMain Icono principal que activa el OffCanvas.
 * @param {String} title Título del OffCanvas.
 * @param {JSX.Element} children Contenido del OffCanvas.
 * @param {JSX.Element} footer Footer del OffCanvas.
 * @param {String} location Ubicación del OffCanvas, puede ser 'left' o 'right'.
 * @param {Function} onShow Función que se ejecuta cuando se muestra el OffCanvas.
 * @param {Function} onHide Función que se ejecuta cuando se oculta el OffCanvas.
 * @returns {JSX.Element}
 */

export const OffCanvas = ({iconMain, title, children, footer, location = 'right', onShow=()=>{}, onHide=()=>{} }) => {

    const root = document.getElementById('root'); // O cualquier otro ID de tu elección

    const offcanvasRef = useRef(null);

    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const handleToggleOffcanvas = (e) => {
        e.stopPropagation();
        setShowOffcanvas(!showOffcanvas);
        onShow()
    };

    const handleOutsideClick = (event) => {
        if (offcanvasRef.current && !offcanvasRef.current.contains(event.target)) {
            setShowOffcanvas(false);
            onHide()
        }
    };

    useEffect(() => {

        if (location !== 'left' && location !== 'right') {
            console.error('En el compoenente offcanvas, la propiedad location debe ser "left" o "right"');
            location = 'right';
        }

        document.addEventListener('mousedown', handleOutsideClick);

        if (showOffcanvas){
            document.body.style.overflow='hidden'
        } else {
            document.body.style.overflow='auto'
        }
        
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.body.style.overflow='auto'
        };

    }, [showOffcanvas]);

    return (
        <>
            <div onClick={handleToggleOffcanvas} className={'btn position-relative'} >
                { iconMain }
            </div>
            {
                showOffcanvas && createPortal(
                    <div 
                        className={`offcanvas-custom ${location} ${showOffcanvas ? `open-${location}` : ''} ${showOffcanvas ? 'd-block' : 'd-none'}`} 
                        ref={offcanvasRef}
                    >
                        <div className={'offcanvas-header-custom d-flex w-100 justify-content-between p-2'}>
                            <h5 className="offcanvas-title mb-3" id="offcanvasExampleLabel">
                                { title }
                            </h5>
                            <i onClick={handleToggleOffcanvas} >
                                <AiOutlineCloseCircle className={'fs-3'} style={{cursor: 'pointer'}} />
                            </i>
                        </div>
                        <div className="offcanvas-body-custom overflow-y-auto" style={{ height: '90%', overflowX: 'hidden' }}>
                            { children }
                        </div>
                        <footer className={'offcanvas-footer-custom'}>
                            { footer }
                        </footer>
                    </div>,
                    root
                )
            }
        </>
    )
}
