import {useState, useEffect} from "react";
import {useRequestGet} from "./useRequestGet";

export function useDataCollectionRequest ( url, params = {}, method, refresh = false ) {

    const { data } = useRequestGet(url, params, refresh);

    const [dataCollectionRequest, setDataCollectionRequest] = useState(null);
    const [error, setError] = useState(null);
    

    useEffect(() => {

            try {
                console.log("useDataCollectionRequest: ", data);
                if (data) {
                    if (method === 'row') {
                        setDataCollectionRequest(data[0]);

                    } else {
                        setDataCollectionRequest(data);
                    }
                }

            } catch (error) {
                setError(error);
                console.log("useDataCollectionRequest: ", error);
            }

    },[data, method]);


    return {
        dataCollectionRequest,
        setDataCollectionRequest,
        error
    };
}