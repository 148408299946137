import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Verificar si el usuario ya aceptó las cookies
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    // Guardar el consentimiento en localStorage
    localStorage.setItem("cookieConsent", "true");
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div style={styles.banner}>
        <p style={styles.text}>
          Usamos cookies para mejorar tu experiencia en nuestro sitio. Al
          continuar navegando, aceptas nuestra{" "}
          <NavLink to="/politica-cookies" style={styles.link}>
            Política de Cookies
          </NavLink>.
        </p>
        <button onClick={handleAccept} style={styles.button}>
          Aceptar
        </button>
      </div>
    )
  );
};

// Estilos en línea
const styles = {
  banner: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#dedede",
    color: "#fff",
    padding: "1rem",
    textAlign: "center",
    zIndex: 1000,
  },
  text: {
    margin: "0 0.5rem 0.5rem 0",
    color: "#fff",
  },
  link: {
    color: "#a74119",
    textDecoration: "underline",
  },
  button: {
    backgroundColor: "#a74119",
    color: "#fff",
    border: "none",
    padding: "0.5rem 1rem",
    cursor: "pointer",
    borderRadius: "5px",
  },
};

export default CookieBanner;
