// src/hooks/useData.js
import {queryClient} from './queryClient';
import { requestGet } from './requestGet';

/**
 * Realiza prefetching para cargar datos en caché antes de usarlos.
 * @param {string} serviceName - Nombre del servicio.
 * @param {object} params - Parámetros opcionales.
 * @param {boolean} enableToken - Si debe incluirse el token.
 */
export const prefetchData = (serviceName, params, enableToken = true) => {
    return queryClient.fetchQuery({
        queryKey: [serviceName, params],
        queryFn: () => requestGet(serviceName, params, enableToken),
    });
};
