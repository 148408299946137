import React from "react";
import { GiShoppingBag } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

export const Card = ({ id, total, order_id, created_at, status, updated_at, product_name, product_quantity }) => {
  const navigate = useNavigate();

  const _created_at = new Date(created_at).toLocaleDateString("es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const _updated_at = new Date(updated_at).toLocaleDateString("es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const handleViewDetails = () => {
    navigate(`/profile/my_shopping/view/${order_id}/${id}`);
  };
  
  const handleViewPackageTracking = () => {
    navigate(`/profile/my_shopping/view/package_tracking/${order_id}`);
  };
  
  const handleViewShoppingTicket = () => {
    navigate(`/profile/my_shopping/view/ticket/${order_id}`);
  };

  return (
    <div className="card w-100 pt-1 pb-1 shadow-none border border-1">
      
      <div className="card-header">
        <div className="row justify-content-between">          
          <div className="col-3">
            <p className="card-title">Pedido realizado</p>
            <p className="card-text">{_created_at}</p>
          </div>
          <div className="col-2">
            <p className="card-title">Total</p>
            <p className="card-text">{total}</p>
          </div>
          <div className="col-7">
            <p className=" text-end ">PEDIDO N.º {order_id} </p>
          </div>
        </div>
      </div>

      <div className="row g-0 m-0 p-0">
        <div className="col-md-4">
          <div className="d-flex m-auto h-100 w-100 justify-content-center align-items-center">
            <GiShoppingBag
              className=" img-fluid rounded-start"
              style={{ fontSize: "3rem" }}
              alt="..."
            />
          </div>
        </div>
        <div className="col-md-8">
          <div className="card-body m-0 p-1">
            <div className="d-flex justify-content-between">
              <h5 className="card-title">  Mi compra </h5>
              <p className="card-text"> Cantidad: {product_quantity} </p>
              <p className="card-text">Total: {total}</p>
              <p className="card-text">{status}</p>
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-sm btn-link"
                onClick={handleViewDetails}
              >
                Ver detalles
              </button>
              <button
                className="btn btn-sm btn-link"
                onClick={handleViewPackageTracking}
              >
                Ver seguimiento
              </button>
              <button
                className="btn btn-sm btn-link"
                onClick={handleViewShoppingTicket}
              >
                Ver recibo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <small className="text-muted">
          Última actualización desde el {_updated_at}{" "}
        </small>
      </div>
    </div>
  );
};
