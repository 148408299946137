import { OffCanvas } from "../OffCanvas/OffCanvas";
import { Filters } from "./Filters";
import { AiOutlineFilter } from "react-icons/ai";


export const FilterNew = () => {
  

    return (
        <>
            <OffCanvas
                iconMain={
                    <div className=" m-3">
                        <AiOutlineFilter className="fs-4" /> Filtros
                    </div>
                }
                title={'Filtros'}
                footer={''}
                location={'left'}

            >
                <Filters />
            </OffCanvas>
        </>
    );
}
