import React from "react";
import { Route, Routes } from "react-router-dom";

import { UpdateUser, Configuration, MyProfile, WishList, Address, AddressForm, MyShopping, PaymentMethod, AddPaymentMethod } from "../View";
import { Cars } from "../MyCars/Cars";
import { ViewShopping } from "../MyShopping/ViewShopping";
import { PackageTracking } from "../MyShopping/PackageTracking";
import { ViewRecibo } from "../MyShopping/ViewRecibo";
import { WriteReview } from "../MyShopping/WriteReview";

export const RoutesProfile = () => {
  return (
    <Routes>
      <Route path="settings" element={<Configuration />} />
      <Route path="view" element={<MyProfile />} />
      <Route path="update" element={<UpdateUser />} />
      <Route path="wishlist" element={<WishList />} />
      <Route path="address" element={<Address />} />
      <Route path="address/add" element={<AddressForm />} />
      <Route path="address/:id/update" element={<AddressForm />} />
      <Route path="my_shopping" element={<MyShopping />} />
      <Route path="my_shopping/view/:order_id/:sale_id" element={<ViewShopping />} />
      <Route path="my_shopping/view/package_tracking/:id" element={<PackageTracking />} />
      <Route path="my_shopping/view/ticket/:id" element={<ViewRecibo />} />
      <Route path="my_shopping/view/write_review/:id" element={<WriteReview />} />
      {/* <Route path="payment" element={<PaymentMethod />} />
      <Route path="payment/add" element={<AddPaymentMethod />} /> */}
      <Route path="cart" element={<Cars />} />
    </Routes>
  );
};
