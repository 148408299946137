import { useState } from "react"
import { StoreContextMain } from "./StoreContextMain"


export const StoreProvider = ({children}) => {

    const [store, setStore] = useState([])
    const [myWish, setMyWish] = useState([])
    const [search, setSearch] = useState('')
    const [filtersContext, setFilterContext] = useState([])
    const [selectedCharacteristics, setSelectedCharacteristics] = useState({
        color: '',
        size: '',
        amount: 0
    })


    return (
        <StoreContextMain.Provider
            value={{
                store,
                setStore,
                myWish,
                setMyWish,
                search,
                setSearch,
                filtersContext, 
                setFilterContext,
                setSelectedCharacteristics,
                selectedCharacteristics,
            }}
        >
            {children}
        </StoreContextMain.Provider>
    )
}