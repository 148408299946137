import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { LoginPage } from '../Page'
import { Register } from '../Page/Register'
import { MyAccount } from '../Page/MyAccount'

export const RouterAuth = () => {

  return (
      <Routes>
          <Route path='login' element={<LoginPage />} />
          <Route path='register' element={<Register />} />
          <Route path="my-account" element={<MyAccount />} />
      </Routes>
  )
}
