import React from 'react'

/**
 * Componente que muestra las políticas de privacidad
 * @returns 
 * Política de Privacidad de Cabaña Rodeo Country
En Cabaña Rodeo Country, valoramos tu privacidad y nos comprometemos a proteger tu información personal. Esta política describe cómo recopilamos, usamos, almacenamos y protegemos tus datos.

1. Información que Recopilamos
Podemos recopilar la siguiente información personal cuando visitas nuestro sitio, realizas una compra o interactúas con nosotros:

Nombre completo.
Dirección de correo electrónico.
Dirección de envío y facturación.
Número de teléfono.
Información de pago (nunca almacenamos los datos de tu tarjeta; estos son procesados por plataformas seguras como PayPal o Stripe).
Dirección IP y datos de navegación (como cookies).
2. Uso de la Información
La información que recopilamos se utiliza para:

Procesar y gestionar tus pedidos.
Enviar actualizaciones sobre el estado de tu compra.
Proporcionar soporte al cliente.
Enviar comunicaciones promocionales (con tu consentimiento).
Mejorar nuestra experiencia de usuario y analizar el rendimiento del sitio web.
3. Compartición de la Información
No compartimos tu información personal con terceros, excepto en los siguientes casos:

Proveedores de servicios: Compartimos datos necesarios con servicios de mensajería, plataformas de pago y herramientas de marketing.
Cumplimiento legal: Si es requerido por ley o autoridad competente.
Protección contra fraude: Para prevenir actividades ilegales o proteger nuestros derechos legales.
4. Uso de Cookies
Utilizamos cookies para mejorar tu experiencia en nuestro sitio web. Las cookies permiten:

Recordar tus preferencias.
Mostrarte productos relevantes.
Analizar el tráfico y el comportamiento de navegación.
Puedes desactivar las cookies desde la configuración de tu navegador, aunque esto podría afectar el funcionamiento del sitio.

5. Almacenamiento de la Información
Guardamos tu información personal de forma segura en servidores protegidos mediante:

Encriptación.
Acceso restringido.
Monitoreo constante de seguridad.
Solo mantenemos tus datos mientras sea necesario para cumplir los fines descritos en esta política o según lo requiera la ley.

6. Tus Derechos
Como usuario, tienes los siguientes derechos sobre tu información personal:

Acceso: Puedes solicitar una copia de los datos que tenemos sobre ti.
Corrección: Puedes pedirnos que actualicemos información incorrecta o incompleta.
Eliminación: Puedes solicitar que borremos tus datos personales, excepto cuando debamos conservarlos por razones legales.
Oposición: Puedes oponerte al uso de tus datos para fines de marketing.
Para ejercer tus derechos, escríbenos a soporte@vaqueroelegante.com.

7. Protección de Datos para Menores
Nuestro sitio no está diseñado para menores de 18 años. No recopilamos intencionalmente información de menores. Si un padre o tutor detecta que un menor ha proporcionado datos, contáctanos para eliminarlos.

8. Cambios a Esta Política
Podemos actualizar esta política de privacidad ocasionalmente. Notificaremos cualquier cambio importante en esta página y, si es necesario, pediremos tu consentimiento nuevamente.

9. Contacto
Si tienes preguntas sobre esta política o el manejo de tu información personal, no dudes en contactarnos:

Correo electrónico: soporte@vaqueroelegante.com
Teléfono: +52 123 456 7890
 * 
 */

export const PoliticasPrivacidad = () => {
  
    return (
        <div>
            <div className="container">
                <h1 className="text-center mt-5"> Política de Privacidad de Cabaña Rodeo Country </h1>
                <p>
                    En Cabaña Rodeo Country, valoramos tu privacidad y nos comprometemos a proteger tu información personal. Esta política describe cómo recopilamos, usamos, almacenamos y protegemos tus datos.
                </p>
                <p>
                    <strong>1. Información que Recopilamos</strong><br />
                    Podemos recopilar la siguiente información personal cuando visitas nuestro sitio, realizas una compra o interactúas con nosotros:
                    <ul>
                        <li>Nombre completo.</li>
                        <li>Dirección de correo electrónico.</li>
                        <li>Dirección de envío y facturación.</li>
                        <li>Número de teléfono.</li>
                        <li>Información de pago (nunca almacenamos los datos de tu tarjeta; estos son procesados por plataformas seguras como PayPal o Stripe).</li>
                        <li>Dirección IP y datos de navegación (como cookies).</li>
                    </ul>
                </p>
                <p>
                    <strong>2. Uso de la Información</strong><br />
                    La información que recopilamos se utiliza para:
                    <ul>
                        <li>Procesar y gestionar tus pedidos.</li>
                        <li>Enviar actualizaciones sobre el estado de tu compra.</li>
                        <li>Proporcionar soporte al cliente.</li>
                        <li>Enviar comunicaciones promocionales (con tu consentimiento).</li>
                        <li>Mejorar nuestra experiencia de usuario y analizar el rendimiento del sitio web.</li>
                    </ul>
                </p>
                <p>
                    <strong>3. Compartición de la Información</strong><br />
                    No compartimos tu información personal con terceros, excepto en los siguientes casos:
                    <ul>
                        <li>Proveedores de servicios: Compartimos datos necesarios con servicios de mensajería, plataformas de pago y herramientas de marketing.</li>
                        <li>Cumplimiento legal: Si es requerido por ley o autoridad competente.</li>
                        <li>Pro tección contra fraude: Para prevenir actividades ilegales o proteger nuestros derechos legales.</li>
                    </ul>
                </p>
                <p>
                    <strong>4. Uso de Cookies</strong><br />
                    Utilizamos cookies para mejorar tu experiencia en nuestro sitio web. Las cookies permiten:
                    <ul>
                        <li>Recordar tus preferencias.</li>
                        <li>Mostrarte productos relevantes.</li>
                        <li>Analizar el tráfico y el comportamiento de navegación.</li>
                    </ul>
                    Puedes desactivar las cookies desde la configuración de tu navegador, aunque esto podría afectar el funcionamiento del sitio.
                </p>
                <p>
                    <strong>5. Almacenamiento de la Información</strong><br />
                    Guardamos tu información personal de forma segura en servidores protegidos mediante:
                    <ul>
                        <li>Encriptación.</li>
                        <li>Acceso restringido.</li>
                        <li>Monitoreo constante de seguridad.</li>
                    </ul>
                    Solo mantenemos tus datos mientras sea necesario para cumplir los fines descritos en esta política o según lo requiera la ley.
                </p>
                <p>
                    <strong>6. Tus Derechos</strong><br />
                    Como usuario, tienes los siguientes derechos sobre tu información personal:
                    <ul>
                        <li>Acceso: Puedes solicitar una copia de los datos que tenemos sobre ti.</li>
                        <li>Corrección: Puedes pedirnos que actualicemos información incorrecta o incompleta.</li>
                        <li>Eliminación: Puedes solicitar que borremos tus datos personales, excepto cuando debamos conservarlos por razones legales.</li>
                        <li>Oposición: Puedes oponerte al uso de tus datos para fines de marketing.</li>
                    </ul>
                    Para ejercer tus derechos, escríbenos a <a href="mailto:soporte@cabañaRodeoCountry.com">soporte@cabañaRodeoCountry.com</a>.
                </p>
                <p>
                    <strong>7. Protección de Datos para Menores</strong><br />
                    Nuestro sitio no está diseñado para menores de 18 años. No recopilamos intencionalmente información de menores
                    Si un padre o tutor detecta que un menor ha proporcionado datos, contáctanos para eliminarlos.
                </p>
                <p>
                    <strong>8. Cambios a Esta Política</strong><br />
                    Podemos actualizar esta política de privacidad ocasionalmente. Notificaremos cualquier cambio importante en esta página y, si es necesario, pediremos tu consentimiento nuevamente.
                </p>
                <p>
                    <strong>9. Contacto</strong><br />
                    Si tienes preguntas sobre esta política o el manejo de tu información personal, no dudes en contactarnos:
                    <ul>
                        <li>Correo electrónico: <a href="mailto:soporte@cabañaRodeoCountry.com">soporte@cabañaRodeoCountry.com</a></li>
                        <li>Teléfono: +52 462 480 4805</li>
                    </ul>
                </p>

            </div>

        </div>
    )
}
