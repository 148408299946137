

export const Advertisement = ({title1, title2}) => {


  
    return (
        <div
            className="d-flex flex-column text-center justify-content-center"
            style={{
                margin: "2rem 0",
                padding: "0",
            }}
        >
            <div className="leyenda animate__animated animate__backInLeft animate__bounce ">
            <h3
                className="leyenda-title-right p-4"
            >
                {title1}
            </h3>
            </div>
            <div className="leyenda animate__animated animate__backInRight animate__bounce ">
            <h3
                className="leyenda-title-left p-4"
            >
                {title2}
            </h3>
            </div>
        </div>
    );
};
