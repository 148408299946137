const categoryFilters = {
    hombre: { category__name: 'Hombre' },
    mujer: { category__name: 'Mujer' },
    infantil: { category__name: 'Infantil' },
    niños: { category__name: 'Infantil' },
    adultos: { category__name: 'Adultos' },
    jovenes: { category__name: 'Jóvenes' },
    'tercera-edad': { category__name: 'Ansianos' },
    unisex: { category__name: 'Unisex' },
    sombrero: { category__name: 'Sombrero' },
    botas: { category__name: 'Botas' },
    camisa: { category__name: 'Camisa' },
    pantalon: { category__name: 'Pantalón' },
    calzado: { category__name: 'Calzado' },
    accesorios: { category__name: 'Accesorios' },
    chaquetas: { category__name: 'Chaqueta' },
    shorts: { category__name: 'Short' },
    vestidos: { category__name: 'Vestidos' },
    faldas: { category__name: 'Falda' },
    blusas: { category__name: 'Blusa' },
    playeras: { category__name: 'Playeras' },
    zapatos: { category__name: 'Zapatos' },
    sandalias: { category__name: 'Sandalias' },
    tenis: { category__name: 'Tenis' },
    tacones: { category__name: 'Tacones' },
    bolsos: { category__name: 'Bolsos' },
    gorras: { category__name: 'Gorras' },
    bufandas: { category__name: 'Bufandas' },
    pañuelos: { category__name: 'Pañuelos' },
    lentes: { category__name: 'Lentes' },
    joyeria: { category__name: 'Joyería' },
    relojes: { category__name: 'Relojes' },
    carteras: { category__name: 'Carteras' },
    cinturones: { category__name: 'Cinturones' },
    mochilas: { category__name: 'Mochilas' },
    calcetines: { category__name: 'Calcetines' },
    guantes: { category__name: 'Guantes' },
    'ropa-interior': { category__name: 'Ropa interior' },
    promociones: { discounted: true },
    novedades: { new: true },
    ofertas: { offer: true },
    'mas-vendidos': { best_seller: true },
  };
  
  export const applyFilters = (filterKey) => {
    return categoryFilters[filterKey] || {};
  };
  