import { useNavigate } from 'react-router-dom';
import { alertDialog, alertSuccess, requestDelete } from '../../../Helpers';


export const CardAddress = ({id, name, address, city, state, zipcode, phone, updated_at, instructions, ruta=false, deleteAddres=false}) => {

    const navigate = useNavigate();

    const handleUpdate = (ruta) => {
        if (!ruta) {
            return;
        }
        navigate(ruta)
    }

    const handleDelete = () => {
        alertDialog('¿Estás seguro de eliminar esta dirección?, Esta acción no se puede deshacer.', 'Eliminar', 'Cancelar')
            .then( (result) => {
                console.log(result);
                if (result) {
                    requestDelete('shipping_addresses/'+id+'/')
                        .then( (data) => {
                            console.log(data);
                            alertSuccess('Dirección eliminada correctamente')
                            navigate('/profile/address')
                            window.location.reload();
                        })
                }
            }
        )
    }
  
    return (
        <div className='card shadow-none '>
            <div className='card-body'>
                <h5 className='card-title'>Dirección de envío</h5>
                <p className='card-text'>Nombre: {name}</p>
                <p className='card-text'>Dirección: {address}</p>
                <p className='card-text'>Estado: {state}</p>
                <p className='card-text'>Ciudad: {city}</p>
                <p className='card-text'>Código postal: {zipcode}</p>
                <p className='card-text'>Teléfono: {phone}</p>
                <p className='card-text w-100'>
                    Instrucciones
                    <span className='w-100 d-flex flex-wrap text-wrap m-auto'>
                        {instructions}
                    </span>
                </p>
                <div className='d-flex justify-content-around'>
                    {
                        ruta && (<button onClick={()=>handleUpdate(ruta)} className='btn btn-primary'>Editar</button>)
                    }
                    {
                        deleteAddres && (<button onClick={handleDelete} className='btn btn-danger'>Eliminar</button>)
                    }
                </div>
            </div>
            <div className='card-footer'>
                <small className='text-muted'>Última actualización fue {updated_at}</small>
            </div>
        </div>
    )
}
