import { useContext } from "react";
import { AuthContext } from "../Auth";
import { useDataCollectionRequest } from "../Hooks";
import {
  Advertisement,
  CelebrationAnimation,
  IconsRedirect,
  Image,
} from "../Components";
import { BannersCards, BannersImgs, MultiColumnAdversiment } from "../Section";

import IMG00 from "../Assets/cover/00.png";
import BACK1 from "../Assets/cover/back_1.png";
import IMG01 from "../Assets/cover/01.png";
import BACK2 from "../Assets/cover/back_2.png";
import IMG10 from "../Assets/cover/10.png";
import BACK3 from "../Assets/cover/back_3.png";
import IMG11 from "../Assets/cover/11.png";
import BACK4 from "../Assets/cover/back_4.png";
import { AcordionImgs } from "../Components/AcordionImgs";
import { FeaturedCollection } from "../Section/FeaturedCollection";

export const Home = () => {
  const { isRegister } = useContext(AuthContext);

  const { dataCollectionRequest } = useDataCollectionRequest(
    "banners/",
    { tipo: 'PO' },
    "col"
  );

  return (
    <div className={""}>
      {isRegister && (
        <CelebrationAnimation
          message="
                        ¡Gracias por registrarte!
                        \n¡Bienvenido a la familia!
                        \nSumérgete en el mundo Cabaña Rodeo Country y descubre una amplia variedad de somreros, botas,
                        camisas, pantalones y accesorios que te harán lucir a la última moda vaquera. 
                        Desde los clásicos atemporales hasta los estilos más modernos, tenemos el look perfecto para ti.
                        \n !Estamos comprometidos a ofrecerte la mejor calidad y los mejores precios!"
        />
      )}

      <BannersImgs />
      {/* <MainToHome2 /> */}

      <div className="" style={{ margin: "2rem" }}></div>

      <IconsRedirect />

      <div className="" style={{ margin: "2rem" }}></div>

      <div className="cover_imgs_publicity">
        <div className="cover_imgs_publicity__img" data-text="Anuncio 1">
          <img srcSet={IMG00} className="img-fluid front" alt="" />
          <img srcSet={BACK1} className="img-fluid back" alt="" />
          <div className="text-overlay">
            Tenemos todo lo que necesitas para lucir a la moda vaquera.
          </div>
        </div>
        <div className="cover_imgs_publicity__img" data-text="Anuncio 2">
          <img srcSet={IMG10} className="img-fluid front" alt="" />
          <img srcSet={BACK2} className="img-fluid back" alt="" />
          <div className="text-overlay">
            Envíos a todo México. ¡No te quedes sin el tuyo!
          </div>
        </div>
        <div className="cover_imgs_publicity__img" data-text="Anuncio 3">
          <img srcSet={IMG01} className="img-fluid front" alt="" />
          <img srcSet={BACK3} className="img-fluid back" alt="" />
          <div className="text-overlay">
            Siempre dispuestos a ofrecerte lo mejor del mundo vaquero.
          </div>
        </div>
        <div className="cover_imgs_publicity__img" data-text="Anuncio 4">
          <img srcSet={IMG11} className="img-fluid front" alt="" />
          <img srcSet={BACK4} className="img-fluid back" alt="" />
          <div className="text-overlay">
            ¡Descubre la mejor moda vaquera en Cabaña Rodeo Country!
          </div>
        </div>
      </div>

      <div className="" style={{ margin: "2rem" }}></div>

      {/* <div className="container">
                <GridCards filters="?best_seller=true" />
            </div> */}

      <div className="container">
        <div className="row">
          <h2 className=" ">¡Novedades!</h2>
        </div>
        <BannersCards filters="?new=True&page_size=10" />
      </div>

      <div className="" style={{ margin: "5rem" }}></div>

      <div className="container">
        <AcordionImgs />
      </div>

      <div className="" style={{ margin: "5rem" }}></div>

      <div className="">
        <MultiColumnAdversiment />
      </div>

      <div className="" style={{ margin: "6.5rem" }}></div>

      {/* <Advertisement title1={'¡Llevate lo que desees!'} title2={'!Con ofertas desde el 30%!'}/> */}

      <div className="container">
        <div className="row gap-md-0 gap-4">
          <div className="col-md-4 m-auto">
            <Image
              src={dataCollectionRequest?.results[0]?.image}
              className="img-fluid"
              alt={dataCollectionRequest?.results[0]?.description}
            />
          </div>
          <div className="col-md-8 m-auto">
            <BannersCards filters="?category__name=Botas&page_size=10" />
          </div>
        </div>
      </div>

      <div className="" style={{ margin: "2rem" }}></div>

      {/* <Commercial enableBanner={true} percent={'10%'} comercial={'DE DESCUENTO EN TODA LA TIENDA EN LINEA, !NO ESPERES MÁS¡'}/> */}
      <Advertisement
        title1={"¡Ofertas en ropa de mujeres!"}
        title2={"!Llevatela a meses sin intereses!"}
      />

      <div className="" style={{ margin: "2rem" }}></div>

      <div className="container">
        <div className="row">
          <FeaturedCollection />
        </div>
      </div>

      <div className="" style={{ margin: "6rem" }}></div>

      {/* <div className="container">
                <GridCards filters="?best_seller=true" />
            </div>

            <Advertisement title1={'¡Ofertas en ropa de mujeres!'} title2={'!Llevatela a meses sin intereses!'}/> */}

      <div className="container">
        <div className="row gap-md-0 gap-4">
          <div className="col-md-8 order-1 order-md-0 m-auto">
            <BannersCards filters="?category__name=Sombrero&page_size=10" />
          </div>
          <div className="col-md-4 order-0 m-auto">
            <Image
              src={dataCollectionRequest?.results[1]?.image}
              className="img-fluid "
              style={{ objectFit: "cover" }}
              alt={dataCollectionRequest?.results[1]?.description}
            />
          </div>
        </div>
      </div>

      {/* <Advertisement title1={'¡Ofertas en ropa de mujeres!'} title2={'!Llevatela a meses sin intereses!'}/> 

            <div className="container">
                <div className="row gap-md-0 gap-4">
                    <div className="col-md-4 m-auto">
                        <img srcSet={ dataCollectionRequest?.results[2]?.image } className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-8 m-auto">
                        <BannersCards filters="?new=True" />
                    </div>
                </div>
            </div>

            <Advertisement title1={'¡Ofertas en ropa de mujeres!'} title2={'!Llevatela a meses sin intereses!'}/> 

            <div className="container">
                <div className="row gap-md-0 gap-4">
                    <div className="col-md-8 order-1 order-md-0 m-auto">
                        <BannersCards filters="?category__name=Infantil&page_size=10" />
                    </div>
                    <div className="col-md-4 order-0 m-auto">
                        <img srcSet={ dataCollectionRequest?.results[3]?.image } className="img-fluid" alt="" />
                    </div>
                </div>
            </div> */}

      <div className="" style={{ margin: "2rem" }}></div>
    </div>
  );
};
