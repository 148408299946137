import { useEffect, useState } from 'react'

export const ContentShowMore = ({text = ''}) => {

    const [showMore, setShowMore] = useState(false);

    useEffect( ()=>{
        // text = dangerouslySetInnerHTML={{ __html: text }}
    }, [text] )

    const handleShowMore = (e) => {
        setShowMore(!showMore)
    }

    return (
        <span>
            <span
                dangerouslySetInnerHTML={{ __html: showMore ? text : `${text.substring(0, 250)}...` }}
            />
            {/* {showMore ? text : `${text.substring(0, 250)}...`} */}
            <button
                className="btn btn-link"
                onClick={(e)=>handleShowMore(e)}
            >
                {
                    showMore ? 'Mostrar menos' : 'Mostrar más'
                }
            </button>
        </span>
    )
}
