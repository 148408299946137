import React from 'react'
import LazyLoad from "react-lazyload";

export const Image = ({ src, alt, style, className, draggable = false }) => (
  <LazyLoad className=' h-100'  offset={100} once>
    <picture>
      <source srcSet={src} alt={alt} style={style} className={className} draggable={draggable} />
      <img src={src} alt={alt} style={style} className={className} draggable={draggable} loading="lazy" decoding="async" />
    </picture>
  </LazyLoad>
);