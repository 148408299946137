import { requestPost } from "../../../Helpers"


export const deleteOrderItem = async (id) => {

    await requestPost(`orders_items/delete_to_orderitem/`, id)
        .then((data) => {
            console.log("data", JSON.parse(data));
            return JSON.parse(data)
        })
        .catch((error) => {
            console.error('Error:', error);
        });

}