import { FiShoppingCart } from "react-icons/fi";
import { OffCanvas, CardOrder } from "../../Components";
import { useContext, useEffect } from "react";
import { StoreContextMain } from "../../Context";
import { AuthContext, useChekingAuth } from "../../Auth";
import { useNavigate } from "react-router-dom";
import { alertDialog } from "../../Helpers/Alerts";
import { requestGet } from "../../Helpers";
import { jwtDecode } from "jwt-decode";
import { getOrder } from "../../Components/Cards/helpers";

/*
Este es el carrito de compra, que sale el header, para visualizar los arituclos rápidamente
*/
export const Car = () => {
  const { store, setStore } = useContext(StoreContextMain);
  
  const { logged } = useChekingAuth();

  const navigate = useNavigate();

  // useEffect para obtener los productos del carrito de compra
  useEffect(() => {
    if (logged) {
      getStore();
    }
  }, [logged]);

  // Función para obtener los productos del carrito de compra cuando se monta el componente
  const getStore = async () => {
    const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));

    let order_id = null
    
    const decoded = jwtDecode(token)

    await getOrder(decoded?.user_id)
      .then((data) => {
        order_id = data?.results[0]?.uuid
      })
      .catch((error) => {
        order_id = null
      });

      if (order_id === null || order_id === undefined) {
        return setStore([])
      };
      
      await requestGet(`order_items/`, {"order": order_id})
      .then((data) => {
        data = data?.results.map((item) => {
          // se obtiene la información del producto
          const product = {
            id: item?.category?.product_uuid,
            title: item?.category?.product_name,
            price: item?.price,
            image: item?.category?.product_image !== null ? item?.category?.product_image[0]?.image : '',
            color: '',
            size: '',
            amount: item?.quantity,
            amount_max: item?.category?.stock,
            order: order_id
          }

          // se obtiene el color y la talla del producto
          item?.category?.category?.forEach( category => {

            if (category.category === "Colores") {
              product.color = category?.name;
            } else if (category.category === "Tallas") {
              product.size = category?.name;
            }

          })
          
          return product
        } );

        setStore(data ?? []);
      })
      .catch((error) => {
        setStore([]);
      });

  };

  // Función para navegar a la página de detalles de la compra
  const handleNavigate = () => {
    if (store.length <= 0 ) {
      return alertDialog('No existen productos seleccionados, \n favor de agregar', 'Entendido', '')
    }
    navigate("/payment/details-payment");
  };

  const handleOpenCar = () => {
    // Check logged state outside of useEffect
    if (!logged) {
      navigate("/", { replace: true });
      return null; // Early return to prevent unnecessary rendering
    }  
  }

  return (
    <>
      <OffCanvas
        iconMain={
          <button
            className="m-0 p-0 border-0 bg-transparent"
            onClick={()=>handleOpenCar}
          >
            <FiShoppingCart className={"fs-3"} />
            <span
              className="position-absolute start-50 translate-middle bg-danger border border-light rounded-circle"
              style={{
                top: "5px",
                padding: "0.25rem",
                display: store.length > 0 ? "block" : "none",
              }}
            >
              <span className="visually-hidden">New</span>
            </span>
          </button>
        }
        title={
          <div>
            <h5 className="d-flex m-auto align-items-center">
              <FiShoppingCart className={"fs-3 mx-2 me-3"} />
              Mi Carrito
            </h5>
          </div>
        }
        footer={
          <div className="d-flex justify-content-center mb-3">
            <button onClick={handleNavigate} className={"btn btn-primary"}>
              Llevar acabo la compra
            </button>
          </div>
        }
      >
        {store?.map((item, index) => (
          <CardOrder
                key={index}
                id={item.id}
                title={item.title}
                image={item.image}
                price={item.price}
                color={item.color}
                size={item.size}
                amount={item.amount}
                amount_max={item.amount_max}
                enableFeacture={false}
          />
        ))}
      </OffCanvas>
    </>
  );
};
