import { useContext, useState } from "react";
import { StoreContextMain } from "../../Context/StoreContextMain";
import { Count } from "../Characteristics";
import { Image } from "../LazyLoad";
import { CiTrash } from "react-icons/ci";
import IMGLOGO from '../../Assets/img/logoRopa.png';

export const CardOrder = ({ id, title, image, price, color, size, amount, amount_max, enableFeacture = false }) => {
  
  const { store, setStore } = useContext(StoreContextMain);

  const [count, setCount] = useState(amount);

  const removeItem = () => {
    const newStore = store.filter((item) => item.id !== id);
    setStore(newStore);
  };

  return (
    <div className="card shadow-none p-0 mb-3" style={{ minHeight: "120px" }} tabIndex={id}>
      <div className="row ">
        <div
          className="col-4 d-flex justify-content-center align-content-center"
          style={{ height: "110px" }}
        >
          <Image
            src={image || IMGLOGO}
            alt="Producto"
            style={{ maxWidth: "100%" }}
            className="img-fluid rounded-start"
          />
        </div>
        <div className="col-8">
          <div className="card-body">
            <h5 className="card-title m-0 p-0" style={{ fontSize: "15px" }}>
              {title}
            </h5>
            
            <div className="d-flex flex-row justify-content-around ">
              <p className="card-text m-0 p-0" style={{ fontSize: "13px" }}>
                <small className="text-muted">${price}</small>
              </p>
              {
                enableFeacture && ( 
                  <>
                    <p className="card-text m-0 p-0" style={{ fontSize: "13px" }}>
                      <small className="text-muted">Color: {color}</small>
                    </p>
                    <p className="card-text m-0 p-0" style={{ fontSize: "13px" }}>
                      <small className="text-muted">Talla: {size}</small>
                    </p>
                  </>
                )
              }
            </div>
            <div className="w-100">
              <div className={`p-0 m-0 d-flex flex-row justify-content-around align-items-center mt-1`}>
                <div className="d-flex flex-column">
                  <Count
                    count={count}
                    setCount={setCount}
                    cantidad_max={amount_max}
                  />
                </div>
                <button className={"btn btn-sm "} onClick={removeItem}>
                  <CiTrash className="fs-4 text-danger" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
