import { forwardRef } from 'react'
import LOGO from '../Assets/img/logoRopa.png'

export const Recibo = forwardRef (({
    user = "Nombre del comprador",
    address = "Dirección del comprador",
    city = "Ciudad del comprador",
    state = "Estado del comprador",
    phone = "Telefono del comprador",
    instructions = "Instrucciones del comprador",
    productsList = [],
    total = 0,
    discount = 0,
    iva = 0,
    costo_envio = 0,
    reference = "Referencia del pago"
}, ref) => {
 
    return (
        <div className="row card shadow-none" ref={ref}>
            
            <div className='row mb-3'>
                <div className="col-md-6">
                    <h2>
                        <span>
                            <img alt="" srcSet={LOGO} height="100" />  Tordillo Vaquero{" "}
                        </span>
                    </h2>
                </div>
                
                <div className="col-md-6 text-right">
                    <div className="card shadow-none p-0">
                    <div className="card-header">
                        <h6>
                            NIT: <i> N&uacute;mero de NIT </i>
                        </h6>
                        <h6>
                            AUTORIZACI&Oacute;N: <i>N&uacute;mero de Aut.</i>
                        </h6>
                    </div>
                    <div className="card-body">
                        <h6>
                            Número de recibo: <i>{reference}</i>
                        </h6>
                    </div>
                    </div>
                </div>
            </div>

            <hr />

            <h1 style={{ textAlign: "center" }}>RECIBO DE COMPRA</h1>

            <div className="row">
                <div className="col-xs-12">
                    <div className="card shadow-none p-0">
                        <div className="card-header">
                            <div>
                                Fecha: 
                                <i>
                                    { new Date().toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }
                                </i>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="d-table w-100">
                                <div className="d-table-row">
                                    <h6 className="d-table-cell"> Comprador: <i > {user} </i> </h6>
                                    <h6 className="d-table-cell"> Dirección: <i > {address} </i> </h6>
                                </div>
                                
                                <div className="d-table-row">
                                    <h6 className="d-table-cell"> Ciudad: <i>{city}</i> </h6>
                                    <h6 className="d-table-cell"> Estado: <i>{state}</i> </h6>
                                </div>

                                <div className="d-table-row">
                                    <h6 className="d-table-cell"> Telefono: <i>{phone}</i> </h6>
                                </div>
                            </div>
                            <div className="mt-3 ">
                                <h6 className="m-0"> Detalles: </h6>
                                <p> {instructions} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <pre></pre>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th style={{ textAlign: "center" }}>
                            <h4>Cantidad</h4>
                        </th>
                        <th style={{ textAlign: "center" }}>
                            <h4>Concepto</h4>
                        </th>
                        <th style={{ textAlign: "center" }}>
                            <h4>Precio unitario</h4>
                        </th>
                        <th style={{ textAlign: "center" }}>
                            <h4>Total</h4>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {/* Aqui se debe implementar la logica para mostrar los productos de la compra */}
                    {
                        productsList?.map((item, index) => (
                            <tr key={index}>
                                <td style={{ textAlign: "center" }}>{item?.amount}</td>
                                <td>
                                    <i > {item?.product_name} </i> / <i > {item?.category_name?.category1} </i> / <i > {item?.category_name?.category2} </i>
                                </td>
                                <td className=" text-end ">${item?.price}</td>
                                <td className=" text-end ">${item?.price}</td>
                            </tr>
                        ))
                    }
                                
                    {/* <tr>
                        <td>&nbsp;</td>
                        <td>
                        <i > &nbsp; </i>
                        </td>
                        <td className="text-right">&nbsp;</td>
                        <td className="text-right ">&nbsp;</td>
                    </tr> */}

                    <tr>
                        <td className=" text-end "></td>
                        <td className=" text-end "></td>
                        <td className=" text-end ">
                            Costo de envio:
                        </td>
                        <td className=" text-end ">
                            <i > ${ costo_envio ?? 0} </i>
                        </td>
                    </tr>

                    <tr>
                        <td className=" text-end "></td>
                        <td className=" text-end "></td>
                        <td className=" text-end ">
                            IVA:
                        </td>
                        <td className=" text-end ">
                            <i > ${ iva ?? 0} </i>
                        </td>
                    </tr>  

                    { 
                        discount > 0 && (
                            <tr>
                                <td className=" text-end "></td>
                                <td className=" text-end "></td>
                                <td className=" text-end ">
                                    Descuento:
                                </td>
                                <td className=" text-end ">
                                    <i > ${ discount ?? 0} </i>
                                </td>
                            </tr>
                        )
                    }

                    <tr>
                        <td className=" text-end "></td>
                        <td className=" text-end "></td>
                        <td className=" text-end ">
                            Total:
                        </td>
                        <td className=" text-end ">
                            <i > ${ total} </i>
                        </td>
                    </tr>

                    <tr>
                        <td className=" text-end "></td>
                        <td className=" text-end "></td>
                        <td className=" text-end "></td>
                        <td className=" text-end ">
                            Son: <i > Veintiocho mil quinientos </i> 
                        </td>
                    </tr>
                </tbody>
            </table>
            <pre></pre>

            <div className="row">
                <div className="col-xs-4">
                    <a href=" ">
                        <img alt="" srcSet="" />
                    </a>
                </div>
                <div className="col-xs-8">
                    <div className="panel panel-info" style={{ textAlign: "right" }}>
                        <h6>
                            {" "}
                            "LA ALTERACI&Oacute;N, FALSIFICACI&Oacute;N O
                            COMERCIALIZACI&Oacute;N ILEGAL DE ESTE DOCUMENTO ESTA PENADO
                            POR LA LEY"
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    )
})
