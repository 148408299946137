import React, { useEffect, useState } from "react";

const statusJSON = {
  Processing: "Procesando",
  Shipped: "Enviado",
  "In Transit": "En tránsito",
  "Out for Delivery": "En camino",
  Delivered: "Entregado",
  Cancelled: "Cancelado",
};

export const StatusTrackStep = ({ currentStatus }) => {

    const [previousStatuses, setPreviousStatuses] = useState([]);

    useEffect(() => {

        const orderedStatuses = Object.keys(statusJSON);

        setPreviousStatuses(
            orderedStatuses.slice(0, orderedStatuses.indexOf(currentStatus) + 1)
        );
        console.log("orderedStatuses", orderedStatuses.slice(0, orderedStatuses.indexOf(currentStatus) + 1));
    
    }, [currentStatus]);

    return (
        <div className="order-track">
        {
            previousStatuses.map((status, index) => (
                <div className="order-track-step" key={statusJSON[status]}>
                    <div className="order-track-status">
                        <span className="order-track-status-dot"></span>
                        <span className="order-track-status-line"></span>
                    </div>
                    <div className="order-track-text">
                        <p className="order-track-text-stat"> { statusJSON[status] } </p>
                        <span className="order-track-text-sub">
                            { status === currentStatus ? "Actual" : "" }
                        </span>
                    </div>
                </div>
            ))
        }
        </div>
    );
};

