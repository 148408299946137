import { requestGet } from "../Helpers";
import { useQuery } from "@tanstack/react-query";

/**
 * Hook personalizado para realizar solicitudes GET con React Query.
 * @param {string} serviceName - Nombre del servicio o endpoint.
 * @param {object} params - Parámetros opcionales para la solicitud.
 * @param {boolean} refresh - Controla la invalidación del caché.
 * @returns {object} - Datos, estado de carga y errores.
 */

export const useRequestGet = (serviceName = '', params = {}, refresh = false) => {
    
    return useQuery({
        queryKey: [serviceName, params],
        queryFn: () => requestGet(serviceName, params),
        refetchOnWindowFocus: false, // Evitar recargas automáticas al enfocar la ventana
        refetchOnMount: false, // Evitar recargas automáticas al montar el componente
        enabled: !!serviceName, // Solo ejecutar si hay un servicio
        keepPreviousData: true, // Mantener datos anteriores mientras se cargan nuevos
        staleTime: refresh ? 0 : 1000 * 60 * 5, // Renovar el caché si `refresh` cambia
    });
};

