import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { BannersCards } from '../../Section/BannersCards';
import { decryptParams } from '../../Helpers';
import { FiPackage } from "react-icons/fi";


export const PaymentSuccess = () => {

    const navigate = useNavigate();

    const { params } = useParams();

    const [decryptedParams, setDecryptedParams] = useState({});

    useEffect(() => {
        console.log(params);
        try {
            const decodeUri = decodeURIComponent(params);
            setDecryptedParams(decryptParams(decodeUri));
            console.log(decryptParams(decodeUri));
        } catch (error) {
            console.log(error);
        }
    }, [params]);

    const handleViewShipment = () => {
        navigate(`/profile/my_shopping/view/${decryptedParams?.order_id}/${decryptedParams?.pay_id}`);
    }

    const handleContinueShopping = () => {
        navigate('/');
    }

    const handleFollowShipment = () => {
        navigate(`/profile/my_shopping/view/package_tracking/${decryptedParams?.order_id}`);
    }
  
    return (
        <div class="payment-success">
            <div class="success-header">
                <h1>¡Gracias por tu compra! 🎉</h1>
                <p>Tu pedido está confirmado y en proceso.</p>
            </div>

            <div class="celebration-animation">
                <FiPackage className='fs-3' />
            </div>

            <div class="order-details">
                <p>Número de pedido: <strong>#12345</strong></p>
                <p>Monto total: <strong>$1,500.00 MXN</strong></p>
            </div>

            <div class="d-flex flex-row justify-content-around next-actions">
                <button class="btn btn-primary view-order" onClick={handleViewShipment}>
                    Ver Pedido
                </button>
                <button class="btn btn-secondary continue-shopping" onClick={handleContinueShopping}>
                    Seguir Comprando
                </button>
                <button class="btn btn-primary follow-shipment" onClick={handleFollowShipment}>
                    Seguir Envío
                </button>
            </div>

            <div class="mt-5 mb-5">
                <hr className='mt-3 mb-3' />
            </div>

            <div class="recommendations">
                <h2>¿Algo más que pueda interesarte?</h2>
                <div className='d-flex flex-wrap'>
                    <BannersCards filters='?alternar=True&page_size=6' />
                </div>
            </div>
        </div>
    )
}
