import { PayPalButtons } from "@paypal/react-paypal-js";
import { alertError, createFormData, encryptParams, requestPost } from "../../../../Helpers";
import { useNavigate } from "react-router-dom";


export const PayPalCustom = ( { order_id } ) => {
  
    const displayOnly = ["vaultable"];
    
    const navigate = useNavigate();

    async function createOrder ( data, actions ) {

        try {

            console.log("desde createOrder",data,actions)

            const formData = createFormData({
                order_id: order_id,
            });

            const response = await requestPost("paypal/create_order/", formData)

            console.log("from create order",response)

            if (response?.code === "ORDER_ALREADY_COMPLETED") {
                const message = `
                    La orden ya ha sido completada, si tienes problemas con tu pedido,
                    por favor contacta a soporte. \n\r
                    Tu número de orden es: ${response?.order} \n
                    Tu número de referencia es: ${response?.reference}
                `;

                return alertError('¡Verifica tu orden!', message);
            }

            if (response.id) {
                return response.id;
            }
            
            const errorDetail = response?.details?.[0];
            const errorMessage = errorDetail
                ? `${errorDetail.issue} ${errorDetail.description} (${response.debug_id})`
                : JSON.stringify(response);

            throw new Error(errorMessage);

        } catch (error) {
            console.error(error);
            alertError("Error al procesar el pago");
            throw error; // Re-throw to handle further if needed
        }

    }

    async function onApprove(data, actions) {

        try {
            console.log("data",data, "actions", actions)
        
            const formData = createFormData({
                order_id: order_id,
                order_paypal_id: data.orderID,
                payer_id: data.payerID,
                payment_id: data.paymentID,
            });
    
            const response = await requestPost("paypal/capture_order/", formData)
            
            console.log("desde onApprove", response)
            
            let captures = response?.purchase_units[0]?.payments?.captures[0]
            console.log("captures",captures)
            
            if (response?.id && captures?.status == "COMPLETED" && captures?.final_capture == true) {
                let params = {
                    order_id: order_id,
                    pay_id: response?.id,
                }

                let encryptedParams = encodeURIComponent(encryptParams(params));
                
                let redirect_url = `/payment/payment-success/${encryptedParams}/`;
                
                return navigate(redirect_url, { replace: true });
            } else {
                throw new Error("Error al procesar el pago");
            }
        } catch (error) {
            console.error(error);
            alertError("Error al procesar el pago");
            throw error; // Re-throw to handle further if needed
        }

    }

    return (
        <>
            <PayPalButtons
                style={{
                    shape: "pill",
                    layout: "vertical",
                    color: "silver",
                    borderRadius: 10,
                    // height: 50,
                }}
                displayOnly={displayOnly}
                createOrder={createOrder}
                onApprove={onApprove}
                showSpinner={true}
            />

        </>
    )
}
