import { useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google';
import { AuthContext } from "../Context";
import { useForm } from "../../Hooks";
import { requestPost } from "../../Helpers";
import { alertError, alertSuccess } from "../../Helpers/Alerts";
import logo from "../../Assets/img/logoRopa.png";

export const LoginPage = () => {
  const { login } = useContext(AuthContext);

  const navigate = useNavigate();

  const { user, password, onInputChange } = useForm();


  const onLogin = () => {
    const formData = new FormData();
    formData.append("email", user);
    formData.append("password", password);

    requestPost("login/", formData)
    .then((resp) => {
      
      if (typeof resp === "object") {
        const { name, email, is_superuser, is_staff, is_active } = resp.user;
        const { access } = resp;

        if (!is_active) {
          alertError(
            "Usuario inactivo",
            "Por favor, contacte al administrador"
          );
          return;
        }

        if (login(name, email, is_superuser, is_active, is_staff, access)) {
          
          alertSuccess(
            "Inicio de sesión exitoso",
            "Bienvenido de nuevo"
          );

          navigate("/", { replace: true });
        }

      } else {
        alertError(
          "Usuario o contraseña incorrectos",
          "Por favor, verifique sus credenciales"
        );
      }
    })
    .catch((error) => {
      console.log(error);
    });

  };

  const onLoginGoogle = (credentialResponse) => {
    const formData = new FormData();
    formData.append("token", credentialResponse?.credential);

    requestPost("login-google/", formData)
      .then((resp) => {

        if (typeof resp === "object") {
          const { name, email, is_superuser, is_active, is_staff } = resp.user;
          const { access } = resp;
          
          if (!is_active) {
            alertError(
              "Usuario inactivo",
              "Por favor, contacte al administrador"
            );
            return;
          }

          if (login(name, email, is_superuser, is_active, is_staff, access)) {
            alertSuccess(
              "Inicio de sesión exitoso",
              "Bienvenido de nuevo"
            );
            navigate("/", { replace: true });
          }

          // window.location.reload();

        } else {
          alertError(
            "Usuario o contraseña incorrectos",
            "Por favor, verifique sus credenciales"
          );
        }
      });
  }

  return (
    <div className="row w-100 mt-5">
      {/* <h1 className='text-center mb-4 mt-4'> Ingresar a mi sesión </h1> */}

      <div className="row m-auto w-100 ">
        <div className="col-12 col-md-6 ">
          <img srcSet={logo} alt="" className="img-fluid p-5" />
        </div>

        <div className="col-12 col-md-6 m-auto">
          <div>
            <h3 className="text-center fs-2 mb-4 mt-4">
              {" "}
              Ingresar a mi cuenta{" "}
            </h3>
          </div>

          <div className="d-flex flex-column justify-content-center w-75 m-auto ">
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                name="user"
                onChange={onInputChange}
              />
              <label htmlFor="floatingInput"> Correo electronico </label>
            </div>

            <div className="form-floating">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
                name="password"
                onChange={onInputChange}
              />
              <label htmlFor="floatingPassword"> Contraseña </label>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-3">
            <GoogleLogin
              onSuccess={ credentialResponse => {
                onLoginGoogle(credentialResponse);
              }}
              onError={() => {
                alertError(
                  "Error al iniciar sesión con Google",
                  "Por favor, intente de nuevo"
                );
              }}
            />
          </div>

          <div className="d-flex justify-content-center">
            <button onClick={onLogin} className="btn btn-primary w-25 mt-3">
              Ingresar
            </button>
          </div>

          <div className="d-flex justify-content-center mt-3">
            <p>
              ¿Aún no tienes una cuenta? Por favor{" "}
              <NavLink to="/register">Registrate</NavLink>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
