
import { useEffect, useState } from 'react'
import { useRequestGet } from '../../Hooks'

export const SelectEstados = ({valueSelected = null, onChange = () => {}}) => {

    const { data } = useRequestGet('estados/')

    const [estados, setEstados] = useState([])

    useEffect(() => {

        try {
            if (data) {
                setEstados( () => data )
            }
        } catch (error) {
            console.error(error);
        }

    }, [data])
  
    return (
        <div className=''>
            <select 
                className="form-select" 
                aria-label="Default select example"
                name='state'
                onChange={(e)=>onChange(e)}
            >
                <option disabled selected>Selecciona un estado</option>
                {
                    estados?.map((estado) => (
                        <option key={estado.id} selected={estado.id == valueSelected} value={JSON.stringify(estado)}>{estado.nombre}</option>
                    ))
                }
            </select>
        </div>
    )
}
