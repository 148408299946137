import { requestPost } from "../../../Helpers";
import Swal from "sweetalert2";

/**
 * Create a new order from buyer
 * @param {String} user_id
 * @returns {Promise}
 * @example
 * createOrder("user_id").then((uuid) => console.log(uuid));
 */
export const createOrder = (user_id) => {
    
    const formData = new FormData();
    formData.append("user", user_id);
    
    return requestPost("orders/", formData)
        .then((data) => {
            console.log("data", data);
            if (typeof data === "object") {
                return data;
            } 
            return null;
        })
        .catch((error) => {
            Swal.fire({
                title: "Error",
                text: "Error al crear la orden, por favor, recargue la ventana e intente nuevamente",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return null;
        });
    
}
