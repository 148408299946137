
import React from 'react'

/*
    Términos y Condiciones de Cabaña Rodeo Country
1. Introducción
Bienvenido a Cabaña Rodeo Country, tu tienda en línea especializada en ropa vaquera de alta calidad. Al acceder y utilizar nuestro sitio web, aceptas cumplir con los términos y condiciones establecidos a continuación. Si no estás de acuerdo con ellos, te pedimos que no utilices este sitio.

2. Definiciones
Usuario: Cualquier persona que navegue, realice compras o utilice los servicios de nuestro sitio web.
Cabaña Rodeo Country: El sitio web operado por nuestra empresa, incluyendo todo su contenido y servicios.
3. Uso del Sitio Web
El usuario se compromete a utilizar este sitio únicamente para fines legales y de acuerdo con las disposiciones aquí establecidas. Queda prohibido:

Usar el sitio para actividades fraudulentas.
Reproducir, copiar o distribuir cualquier contenido sin autorización previa.
4. Productos y Precios
Los productos que se muestran en nuestro sitio web están sujetos a disponibilidad.
Los precios pueden cambiar sin previo aviso, pero los pedidos confirmados mantendrán el precio pactado.
Las imágenes son de referencia y pueden variar ligeramente del producto real.
5. Proceso de Compra
Selecciona los productos y añádelos al carrito de compras.
Completa los datos de envío y selecciona el método de pago.
Recibirás un correo electrónico con la confirmación de tu pedido.
El pago debe ser realizado de acuerdo con los métodos aceptados: tarjeta de crédito/débito, PayPal o transferencia bancaria.
6. Envíos y Entregas
Realizamos envíos únicamente dentro de [país o región].
El tiempo de entrega es de 3 a 7 días hábiles.
Los costos de envío serán calculados al finalizar la compra y dependerán de tu ubicación.
En caso de problemas con la entrega, comunícate con nosotros a soporte@vaqueroelegante.com.
7. Devoluciones y Reembolsos
Dispones de 15 días a partir de la fecha de entrega para devolver un producto.
Los productos deben estar sin usar, en su empaque original y con todas sus etiquetas.
Los reembolsos se procesarán en un plazo de 7 a 10 días hábiles después de recibir el producto devuelto.
8. Propiedad Intelectual
Todo el contenido del sitio, incluidas imágenes, logotipos y descripciones, es propiedad de Cabaña Rodeo Country. Queda estrictamente prohibido su uso sin autorización.

9. Privacidad
Nos comprometemos a proteger tus datos personales. Consulta nuestra Política de Privacidad para más información.

10. Limitación de Responsabilidad
Cabaña Rodeo Country no será responsable por:

Retrasos ocasionados por servicios de mensajería.
Daños o pérdidas causadas por mal uso de los productos adquiridos.
Errores técnicos o interrupciones del sitio web.
11. Modificaciones de los Términos
Nos reservamos el derecho de modificar estos términos en cualquier momento. Te notificaremos cualquier cambio importante mediante un aviso en el sitio web.

12. Contacto
Para cualquier duda o consulta, contáctanos a través de:

Correo electrónico: soporte@vaqueroelegante.com
Teléfono: +52 462 480 4805
13. Legislación Aplicable
Estos términos y condiciones se rigen por las leyes de [país], y cualquier disputa será resuelta en los tribunales de [ciudad].
*/

export const TerminosCondiciones = () => {

    
  
    return (
        <div className="container">
            <h1 className="text-center mt-5"> Términos y Condiciones </h1>
            <p>
                <strong>1. Introducción</strong><br />
                Bienvenido a Cabaña Rodeo Country, tu tienda en línea especializada en ropa vaquera de alta calidad. Al acceder y utilizar nuestro sitio web, aceptas cumplir con los términos y condiciones establecidos a continuación. Si no estás de acuerdo con ellos, te pedimos que no utilices este sitio.
            </p>
            <p>
                <strong>2. Definiciones</strong><br />
                <strong>Usuario:</strong> Cualquier persona que navegue, realice compras o utilice los servicios de nuestro sitio web.<br />
                <strong>Cabaña Rodeo Country:</strong> El sitio web operado por nuestra empresa, incluyendo todo su contenido y servicios.
            </p>
            <p>
                <strong>3. Uso del Sitio Web</strong><br />
                El usuario se compromete a utilizar este sitio únicamente para fines legales y de acuerdo con las disposiciones aquí establecidas. Queda prohibido:<br />
                <ul>
                    <li>Usar el sitio para actividades fraudulentas.</li>
                    <li>Reproducir, copiar o distribuir cualquier contenido sin autorización previa.</li>
                </ul>
            </p>
            <p>
                <strong>4. Productos y Precios</strong><br />
                Los productos que se muestran en nuestro sitio web están sujetos a disponibilidad.<br />
                Los precios pueden cambiar sin previo aviso, pero los pedidos confirmados mantendrán el precio pactado.<br />
                Las imágenes son de referencia y pueden variar ligeramente del producto real.
            </p>
            <p>
                <strong>5. Proceso de Compra</strong><br />
                <ul>
                    <li>Selecciona los productos y añádelos al carrito de compras.</li>
                    <li>Completa los datos de envío y selecciona el método de pago.</li>
                    <li>Recibirás un correo electrónico con la confirmación de tu pedido.</li>
                    <li>El pago debe ser realizado de acuerdo con los métodos aceptados: PayPal.</li>
                </ul>
            </p>
            <p>
                <strong>6. Envíos y Entregas</strong><br />
                Realizamos envíos únicamente dentro de [país o región].<br />
                El tiempo de entrega es de 3 a 7 días hábiles.<br />
                Los costos de envío serán calculados al finalizar la compra y dependerán de tu ubicación.<br />
                En caso de problemas con la entrega, comunícate con nosotros a
                <a href="mailto:soporte@cabañarodeocountry.com"> soporte@cabañarodeocountry.com</a>.
            </p>
            <p>
                <strong>7. Devoluciones y Reembolsos</strong><br />
                Dispones de 15 días a partir de la fecha de entrega para devolver un producto.<br />
                Los productos deben estar sin usar, en su empaque original y con todas sus etiquetas.<br />
                Los reembolsos se procesarán en un plazo de 7 a 10 días hábiles después de recibir el producto devuelto.
            </p>
            <p>
                <strong>8. Propiedad Intelectual</strong><br />
                Todo el contenido del sitio, incluidas imágenes, logotipos y descripciones, es propiedad de Cabaña Rodeo Country. Queda estrictamente prohibido su uso sin autorización.
            </p>
            <p>
                <strong>9. Privacidad</strong><br />
                Nos comprometemos a proteger tus datos personales. Consulta nuestra Política de Privacidad para más información.
            </p>
            <p>
                <strong>10. Limitación de Responsabilidad</strong><br />
                Cabaña Rodeo Country no será responsable por:<br />
                <ul>
                    <li>Retrasos ocasionados por servicios de mensajería.</li>
                    <li>Daños o pérdidas causadas por mal uso de los productos adquiridos.</li>
                    <li>Errores técnicos o interrupciones del sitio web.</li>
                </ul>
            </p>
            <p>
                <strong>11. Modificaciones de los Términos</strong><br />
                Nos reservamos el derecho de modificar estos términos en cualquier momento. Te notificaremos cualquier cambio importante mediante un aviso en el sitio web.
            </p>
            <p>
                <strong>12. Contacto</strong><br />
                Para cualquier duda o consulta, contáctanos a través de:<br />
                Correo electrónico: <a href="mailto:soporte@cabañarodeocountry.com">soporte@cabañarodeocountry.com</a><br />
                Teléfono: +52 462 480 4805
            </p>
            <p>
                <strong>13. Legislación Aplicable</strong><br />
                Estos términos y condiciones se rigen por las leyes de [país], y cualquier disputa será resuelta en los tribunales de [ciudad].
            </p>
        </div>
    )
}
