import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const Pagination = ({ totalItems, itemsByRequest, setPrevious, setNext, currentPage, arraySize, totalPages }) => {

  const navigate = useNavigate();
  const [count, setCount] = useState(currentPage);
  
  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;

    setCount(page);
    setPrevious(page);
    setNext(page);
    navigate(`?currentpage=${page}`);
  };

  const renderPageButtons = () => {
    return Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
      <li
        className={`page-item ${count === page ? "active" : ""}`}
        key={page}
      >
        <button
          className="page-link"
          onClick={() => handlePageChange(page)}
          aria-current={count === page ? "page" : undefined}
        >
          {page}
        </button>
      </li>
    ));
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            aria-label="Previous"
            onClick={() => handlePageChange(count - 1)}
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {/* Botones de números de página */}
        {renderPageButtons()}
        <li className="page-item">
          <button
            className="page-link"
            aria-label="Next"
            onClick={() => handlePageChange(count + 1)}
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};
