import { useMemo, useState } from "react";
import { Banner, Image } from "../Components";
import { useRequestGet } from "../Hooks";
import { NavLink } from "react-router-dom";


export const BannersImgs = () => {
  const { data } = useRequestGet("banners/", { tipo: "BA" });

  const [banners, setBanners] = useState([]);

  useMemo(() => {
    try {
      if (data) {
        setBanners(() => data?.results);
      }
    } catch (error) {
      console.log(error);
    }

  }, [data]);

  return (
    <>
      <Banner
        contentArray={
          banners?.map((banner) => (
            <NavLink to={banner.url} key={banner.uuid}>
              {card(banner.image, banner?.description, "center")}
            </NavLink>
          ))
        }
        slidesPerView={1}
        autoplay={true}
        enableCoverFlow={true}
      />
    </>
  );
};

const card = (img, description, position, handle = () => {}) => {
  // position = position === "center" ? "center" : position === "left" ? "start" : "end";

  return (
    // <button className="card m-0 p-0 d-flex justify-content-center rounded rounded-5 " style={{ width: "calc(100vw - 25px)", height: "calc(100vh - 95px)" }} onClick={()=>handle}>
    <Image
      src={img}
      alt={description}
      className="card-img w-100"
      style={{
        borderRadius: "50px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "scroll",
        minWidth: "calc(100vw - 25px)",
        maxHeight: "calc(100vh - 95px)",
        maxWidth: "100%",
        width: "100%",
        height: "100%",
        objectFit: "cover", // "contain"
      }}
    />
    // </button>
  );
};
