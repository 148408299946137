import { useState, useEffect } from "react";
import { alertError, prefetchData } from "../../Helpers";
import { useParams } from "react-router-dom";
import { StatusTrackStep } from "./Components/StatusTrackStep";

import IMG from "../../Assets/profile/tracking/iconos.webp";
import "../../Assets/Styles/tracking.css";

// status tracking
const statusJSON = {
  "Processing": "procesando",
  "Shipped": "enviado",
  "In Transit": "en tránsito",
  "Out for Delivery": "en camino",
  "Delivered": "entregado",
  "Cancelled": "cancelado",
};


export const PackageTracking = () => {

  const { id } = useParams();

  const [tracking, setTracking] = useState([]);

  useEffect(() => {
    try {
      getStatusTrackingByOrder(id);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getStatusTrackingByOrder = async (order) => {
    
    try {
      const response = await prefetchData(`sale-tracking/`, { order_id: order });
      console.log(response);
  
      if (response?.code === "ORDER_NOT_FOUND") {
        return alertError("¡Orden no encontrada!", "Por favor verifica tu orden");
      }
  
      if (response?.code === "TRACKING_NOT_FOUND") {
        return alertError("¡Rastreo no encontrado!", "Por favor verifica tu rastreo");
      }
  
      setTracking(response?.results[0]);
    } catch (error) {
      console.error(error);
      alertError("Error al obtener el estado del paquete");
      throw error;
    }

  };

  return (
    <div className="container">
      <h1 className="text-center">Rastreo de paquete</h1>

      <div className="row">
        <div className="col-12 col-md-6">
          <section className="root">
            <figure>
              <img className="" srcSet={IMG} alt="" />
              <figcaption>
                <h3> {tracking?.shipping_address?.name} </h3>
                <h5> {tracking?.shipping_address?.address} </h5>
                <h6> {tracking?.shipping_address?.city} | {tracking?.shipping_address?.state} </h6>
              </figcaption>
            </figure>
            <StatusTrackStep currentStatus={tracking?.tracking_status} />
          </section>
        </div>

        <div className="col-12 col-md-6">
          <div>
            <h2>Estado del paquete</h2>
            <p>El paquete se encuentra {statusJSON[tracking?.tracking_status]}</p>
            <p>Fecha de entrega estimada: 21 de noviembre de 2021</p>
          </div>

          <div>
            <h2>Detalles del paquete</h2>
            <p>Paquetería: FedEx</p>
            <p>Guía de rastreo: 123456789</p>
          </div>

          <div>
            <h4 className="text-center">
              Estamos trabajando para que tengas el paquete lo antes posible{" "}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
