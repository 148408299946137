import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useForm } from "../../Hooks";

import logoLawyer from "../../Assets/img/logoRopa.png";

export function UpdateUser() {
  const navigate = useNavigate();

  const [passwordVerificate, setPasswordVerification] = useState("");

  const { dataForm, onInputChange } = useForm({});

  const onSubmit = (data) => {
    setPasswordVerification(data);
  };

  const handleOnclickRegister = (e) => {};

  return (
    <div className="d-flex flex-direccion-row place-content-center place-items-center m-auto">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="fs-1 text-center"> Editar mis datos </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6 align-middle">
            <img
              srcSet={logoLawyer}
              alt="mdo"
              className="col-md-12 p-5 m-auto"
            />
          </div>

          <div className="col-12 col-md-6 align-middle ">
            <div className="input-group mb-3">
              <label className="input-group-text" htmlFor="inputGroup">
                Nombre:{" "}
              </label>
              <input
                className="form-control"
                name="name"
                id="inputGroup"
                onChange={onInputChange}
              />
            </div>

            <div className="input-group mb-3">
              <label className="input-group-text" htmlFor="inputGroup">
                Apellidos:{" "}
              </label>
              <input
                className="form-control "
                name="lastname"
                onChange={onInputChange}
              />
            </div>

            <div className="input-group mb-3">
              <label className="input-group-text" htmlFor="inputGroup">
                {" "}
                Correo:{" "}
              </label>
              <input
                className="form-control "
                name="email"
                type="email"
                onChange={onInputChange}
              />
            </div>

            <div className="input-group mb-3">
              <label className="input-group-text" htmlFor="inputGroup">
                Tipo de usuario:{" "}
              </label>
              <input
                className="form-control"
                name="typeuser"
                onChange={onInputChange}
              />
            </div>

            <div className="input-group mb-3">
              <label className="input-group-text" htmlFor="inputGroup">
                {" "}
                Contraseña{" "}
              </label>
              <input
                className="form-control "
                name="password"
                type="password"
                onChange={onInputChange}
              />
            </div>

            <div className="row">
              <div className="d-flex justify-content-end ">
                <button
                  type="button"
                  className="btn btn-primary w-25 mb-1 "
                  onClick={handleOnclickRegister}
                >
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
