
import { useEffect, useState, useRef  } from 'react'
import {useReactToPrint} from 'react-to-print';
import { Recibo } from '../../Components/Recibo'
import { useParams } from 'react-router-dom';
import { alertError, prefetchData } from '../../Helpers';
import { FaPrint } from "react-icons/fa";


export const ViewRecibo = ({address, productsList, payment}) => {
    
    const contentRef = useRef();
    const reactToPrintFn = useReactToPrint({ contentRef });

    const { id } = useParams();
    const [ticketData, setTicketData] = useState({})

    useEffect(() => {
        try {
            getDataTicketByOrder(id);
        } catch (error) {
            console.log(error);
        }
    }, [id]);

    const getDataTicketByOrder = async (order) => {
        try {
            const response = await prefetchData(`ticket-sales/`, { order_id: order });
            console.log(response);

            if (response?.code === "ORDER_NOT_FOUND") {
                return alertError("¡Orden no encontrada!", "Por favor verifica tu orden");
            }

            if (response?.code === "TICKET_NOT_FOUND") {
                return alertError("¡Ticket no encontrado!", "Por favor verifica tu ticket");
            }

            setTicketData(response?.results[0]);
        } catch (error) {
            console.error(error);
            alertError("Error al obtener el ticket");
            throw error;
        }
    }

    const handlePrint = () => {

    }
  
    return (
        <div className='container-fluid'>
            <div className='d-flex justify-content-around'>
                <div>
                    <h2 className=''>Recibo</h2>
                </div>
                <div className='d-flex flex-row'>
                    <button onClick={reactToPrintFn} className='btn btn-secondary'> <FaPrint /> Imprimir </button>
                </div>
            </div>
            <hr />
            <div>
                <Recibo
                    ref={contentRef}
                    user={ticketData?.shipping_address?.name}
                    address={ticketData?.shipping_address?.address}
                    city={ticketData?.shipping_address?.city}
                    state={ticketData?.shipping_address?.state}
                    phone={ticketData?.shipping_address?.phone}
                    instructions={ticketData?.shipping_address?.instructions}
                    productsList={ticketData?.order_items}
                    total={ticketData?.total}
                    discount={payment?.discount}
                    iva={payment?.iva}
                    costo_envio={payment?.costo_envio}
                    reference={ticketData?.order_paypal_id}
                />
            </div>

        </div>
    )
}
