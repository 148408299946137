import { prefetchData } from "../../../Helpers";

/**
 * Get the active order of a user
 * @param {String} user_id
 * @returns {Promise}
 * @example
 * getOrderActiveByUser("user_id").then((data) => console.log(data));
 */

export const getOrder = async (user_id) => {
    return await prefetchData(`orders/`, {"user": user_id})
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}