import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import React from 'react'
import { PayPalCustom } from './Components'


const INITIAL_OPTIONS = {
    clientId: process.env.REACT_APP_CLIENT_ID_PAYPAL,
    currency: "MXN",
    intent: "capture",
    components: "buttons",
    disableFunding: "card,credit",

    // dataClientToken: "ENWlUwSGDSWVzZlaYSEqEuAjvsONbErEp60OC43vrm9g_Dw_ZSyyXX42bR7bVwc8T1DUt3VJSFmwWOFW",

}

export const MainPaypal = ({order_id}) => {


    return (
        <PayPalScriptProvider deferLoading={false} options={INITIAL_OPTIONS}>
            <PayPalCustom order_id={order_id}/>
        </PayPalScriptProvider>
    )
}
