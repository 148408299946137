import { useContext, useEffect, useState } from 'react'
import { Favorites } from '../Components/Favorites'
import { StoreContextMain } from '../../Context/StoreContextMain'
import { prefetchData } from '../../Helpers'
import { CardFavorite } from '../../Components/Cards/CardFavorite'

export const WishList = () => {

    const { myWish } = useContext(StoreContextMain)

    const [wishList, setWishList] = useState([])

    useEffect(() => {
        try {
            if (myWish) {
                prefetchData(`wishlist_items/`, {"wishlist": myWish})
                    .then((response) => {
                        setWishList(response?.results)
                    })
            }
        } catch (error) {
            console.log(error);
        }

        return () => {
            setWishList([])
        }
    }, [myWish])

    return (
        <div className='container '>
            <div className='row'>
                {/* <div className='col-12 col-md-4 order-md-0 order-1'>
                    <GridCards />
                </div> */}
                <div className='col col-md-4'>
                    <h3 className='text-center m-2'> Mis lista de deseos: </h3>
                    <Favorites />
                </div>
                <div className='col-12 col-md-4 '>
                    {
                        wishList?.map((item) => (
                            <CardFavorite
                                key={item?.product_id.uuid}
                                id={item?.id}
                                title={item?.product_id.name}
                                image={item?.product_id.image?.image}
                                price={item?.product_id.price}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
