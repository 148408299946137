import { useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import { prefetchData } from '../../Helpers'
import { Card } from './Components/Card'

export const MyShopping = () => {

    const [shopping, setShopping] = useState([])

    useEffect(() => {
        try {
            const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));
            
            const decoded = jwtDecode(token)
            
            getAllShopping(decoded?.user_id)

        } catch (error) {
            console.log(error);
        }
    }, [])

    const getAllShopping = (user_id) => {
        prefetchData(`view-sales/`, {"user": user_id})
            .then((data) => {
                setShopping(data || [])
            })
    }

    return (
        <div className='container'>

            <h3 className=''>Mis compras</h3>

            <div className='row justify-content-center m-auto'>
                
                <div className='col-9 mb-2'>
                    {
                        shopping?.results?.map((item) => {
                            console.log('item', item)
                            return (
                                <div className='mb-3'>
                                    <Card
                                        key={item.pk}
                                        id={item.pk}
                                        total={item.total}
                                        order_id={item.order}
                                        status={item.status}
                                        created_at={item.created_at}
                                        updated_at={item.updated_at}
                                        product_quantity={item.order_items.length}
                                    />
                                </div>
                            )
                        } ) 
                    }
                </div>

                <div className='col-3'>
                    <div className='card'>
                        <div className='card-body'>
                            <h5 className='card-text'>Total de compras: { shopping?.count }</h5>
                            <p className='card-text'>  </p>
                        </div>
                    </div>

                </div>  

            </div>
        
        </div>
    )
}
