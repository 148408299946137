import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GridCards } from '../Section/GridCards'
import { FilterNew, Filters, FiltersMoblie } from '../Components/Filters'
import { NotFound } from '../Components/NotFound'
import { StoreContextMain } from '../Context/StoreContextMain'
import { applyFilters, quitarAcentos } from '../Utils'


export const Secciones = () => {

    const {search, filtersContext } = useContext(StoreContextMain)
    
    const {filters, search : searchParam} = useParams()

    const [activeFilters, setActiveFilters] = useState({});

    useEffect(() => {
        console.log('filters: ', filters);
        console.log('search: ', search);

        let updatedFilters = { ...activeFilters };
    
        if (filters) {
            Object.assign(updatedFilters, applyFilters(filters));
        }

    
        if (searchParam === 'name' && search) {
            updatedFilters.name = search;
        }
        
        console.log('filtersContext: ', filtersContext);
        if (filtersContext && typeof filtersContext === 'object') {
            Object.entries(filtersContext).forEach(([key, value]) => {
                let sin_acentos = quitarAcentos(value.toLowerCase());
                console.log('---->: ', applyFilters(sin_acentos));
                console.log('-------- : ', activeFilters);
                
                updatedFilters = {...activeFilters, ...applyFilters(sin_acentos) } 
                
                console.log('updatedFilters: --------> ',  updatedFilters);
            } );
        }

        console.log('updatedFilters: ', updatedFilters);
    
        setActiveFilters(updatedFilters);

        console.log('activeFilters: ', activeFilters);
    
        return () => setActiveFilters({});

      }, [filters, search, searchParam, filtersContext]);

  
    return (
        <div className='row '>
            <div className='d-flex justify-content-between'>
                <FilterNew />
            </div>
            
            <div className='col-12 '>
                <div className='mx-3 me-3'>
                    {
                        Object.keys(activeFilters).length === 0 && (
                            <NotFound
                                icon="🔍"
                                title="No se encontraron resultados"
                                message="Intenta realizar otra búsqueda o vuelve más tarde."
                            />
                        )
                    }
                    <GridCards
                        url={'list-products/'}
                        params = {activeFilters}
                        pagination={true}
                    />
                </div>
            </div>
        </div>
    )
}

