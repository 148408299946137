import { useContext } from "react";
import { PaymentContext } from "../Context/PaymentContext";
import { MainPaypal } from "../Components";
import { BsPersonFill, BsCashStack } from "react-icons/bs";

export const ConfirmPurchase = ({}) => {
  const { payment } = useContext(PaymentContext);

  // metodo para pagar la compra en paypal
  const handlePay = () => {
    // aqui se debe implementar la logica para pagar la compra
  };

  // metodo para guardar la compra en la base de datos
  const handleSave = () => {
    // aqui se debe implementar la logica para guardar la compra
  };

  // metodo para enviar la confirmacion de la compra al correo del usuario
  const handleSendEmail = () => {
    // aqui se debe implementar la logica para enviar el correo
  };

  // metodo para confirmar la compra
  const handleConfirm = () => {
    handlePay();
    handleSave();
    handleSendEmail();
  };

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-12 d-flex flex-column align-items-center">
          <div className="text-center mb-4">
            <h2 className="fw-bold">Resumen de la compra</h2>
            <h4 className="text-muted">
              {new Date().toLocaleDateString("es-ES", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </h4>
          </div>

          <div className="w-100 mb-4">
            <div className="card shadow-lg p-4 border-0">
                <div className="row">
                    <div className="col-md-6">
                        <div className="d-flex align-items-center mb-3">
                            <div
                                className="icon bg-secondary text-white rounded-circle d-flex justify-content-center align-items-center"
                                style={{ width: "50px", height: "50px" }}
                            >
                            <BsPersonFill
                                size={34}
                                className="bi bi-person-fill text-warning"
                            ></BsPersonFill>
                            </div>
                            <h5 className="ms-3">
                                <strong>Información del Comprador</strong>
                            </h5>
                        </div>
                    </div>
                    <div className="col-md-6 d-none d-md-block">
                        <TitleProcederPago />
                    </div>
                </div>
                

                <div className="row gy-2">
                    <div className="col-6">
                        <p className="mb-1">
                            <strong>Nombre:</strong> {payment?.address?.name}
                        </p>
                        <p className="mb-1">
                            <strong>Dirección:</strong> {payment?.address?.address},{" "}
                            {payment?.address?.city}
                        </p>
                        <p className="mb-1">
                            <strong>Cantidad de productos:</strong>{" "}
                            {payment?.productsList.length}
                        </p>
                        <p className="mb-1">
                            <strong>IVA:</strong> ${payment?.iva || 0}
                        </p>
                        <p className="mb-1">
                            <strong>Descuentos:</strong> ${payment?.discount || 0}
                        </p>
                        <p className="mb-1">
                        <strong>Costo de envío:</strong> $
                            {payment?.costo_envio || 0}
                        </p>

                        <div className="col-12 text-center">
                            <div className="badge bg-success fs-5 p-2">
                            <strong>Total:</strong> ${payment?.total}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="d-md-none d-block">
                            <TitleProcederPago />
                        </div>
                        <div className="d-flex align-content-center card shadow-lg justify-content-center gap-4">
                            <div className="stripe">{/* <MainStripe /> */}</div>
                            <div className="paypal">
                                <MainPaypal order_id={payment?.order?.uuid} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const TitleProcederPago = () => {
    return(
        <div className="d-flex align-items-center mb-3">
            <div
                className="icon bg-secondary text-white rounded-circle d-flex justify-content-center align-items-center"
                style={{ width: "50px", height: "50px" }}
            >
            <BsCashStack
                size={34}
                className="bi bi-person-fill text-warning"
            ></BsCashStack>
            </div>
            <h5 className="ms-3">
                <strong>Proceder a pagar</strong>
            </h5>
        </div>
    )
}


// <div className='container'>
//     <div className='row w-100 flex-column justify-content-center text-center'>
//         <h2>Resumen de la compra.</h2>
//         <i> Recibo y confirmación de la compra </i>
//     </div>
//     <div className='row'>
//         <div className='col-12'>
//             <div>
//                 <h3>Detalle</h3>
//                 <ul>
//                     <li>Total por productos { payment?.totalProducts } </li>
//                     <li>Costo de envio ${ payment?.costo_envio } </li>
//                     <li>IVA ${ payment?.iva } </li>
//                     <li>Total (IVA incluido en caso de ser aplicable) ${payment?.total} </li>

//                 </ul>
//             </div>
//             <div>
//                 <h3>Dirección de envio</h3>
//                 <p>
//                     <span>
//                         {
//                             <div>
//                                 <p>Nombre: {payment?.address?.name}</p>
//                                 <p>Dirección: {payment?.address?.address}</p>
//                                 <p>Ciudad: {payment?.address?.city}</p>
//                                 <p>Estado: {payment?.address?.state}</p>
//                                 <p>Código postal: {payment?.address?.zipcode}</p>
//                                 <p>Teléfono: {payment?.address?.phone}</p>
//                                 <p>Actualizado: {payment?.address?.updated_at}</p>
//                             </div>

//                         }
//                     </span>
//                 </p>
//             </div>
//             <div>
//                 <h3>Información del pago</h3>
//                 <p>
//                     <span>
//                         {
//                             <div>
//                                 <p>Número de tarjeta: {payment?.methodPay?.card_number}</p>
//                                 <p>Nombre en la tarjeta: {payment?.methodPay?.card_holder}</p>
//                                 <p>Fecha de expiración: {payment?.methodPay?.month}/{payment?.methodPay?.year}</p>
//                                 <p>CVV: {payment?.methodPay?.cvv}</p>
//                                 <p>Actualizado: {payment?.methodPay?.updated_at}</p>
//                             </div>
//                         }
//                     </span>
//                 </p>
//             </div>
//         </div>
//         <div className=''>
//             <div className='d-flex '>
//                 <button> Realizar pedido</button>
//             </div>
//         </div>
//     </div>
// </div>
