import { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Context';
import { CustomCowBoy } from '../../Components/CowBoy';

export const GoUser = () => {

    const {logged} = useContext(AuthContext);

    const navigate = useNavigate();
  
    const handleGoUser = () => {
        if (logged) {
            return navigate('/profile/view')
        }
        return navigate('/my-account')
    }

    return (
        <CustomCowBoy handleClick={handleGoUser} />
    )
}


