import { FaHatCowboy, FaUser } from "react-icons/fa";


export const CustomCowBoy = ({ handleClick = () => {} }) => {
    
    
    return (
        <button style={styles.btn} onClick={handleClick}>
            <FaHatCowboy 
                className='fs-3' 
                style={styles.icon}    
            />
            <FaUser className='fs-3' style={styles.icon2}/>
        </button>
    )
}

const styles = {
    btn: {
        margin: '0 1.5rem',
        padding: '0',
        height: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        position: 'relative',
    },
    icon: {
        margin: 0,
        padding: 0,
        position: 'absolute',
        top: '10px',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    icon2: {
        margin: 0,
        padding: 0,
        position: 'absolute',
        top: '25px',
        left: '50%',
        transform: 'translate(-50%, -50%)'

    }
}
