import CryptoJS from "crypto-js";


// Clave de encriptación (puedes usar algo más seguro)
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY_PARAMS;

// Encriptar parámetros
export const encryptParams = (params) => {
    const jsonString = JSON.stringify(params);
    return CryptoJS.AES.encrypt(jsonString, SECRET_KEY).toString();
};
