import { requestPost } from "../../../Helpers";
import Swal from "sweetalert2";

/**
 * Create a new wish list
 * @param {String} user_id
 * @returns {Promise}
 * @example
 * createWishList("user_id").then((uuid) => console.log(uuid));
 */

export const createWishList = (user_id) => {

    const formData = new FormData();
    formData.append("user", user_id);
    
    return Swal.fire({
        title: "Ponle nombre a tu nueva lista de deseos",
        input: "text",
        inputAttributes: {
            autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Crear",
        showLoaderOnConfirm: true,
        preConfirm: (name) => {
            formData.append("name", name);
            return requestPost("wishlists/", formData)
            .then((data) => {
                const uuid = data?.uuid || null;
                console.log("data", data);
                return uuid;
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
    });
}
