import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useForm } from "../../Hooks";
import { createFormData, prefetchData, requestPatch, requestPost, requestPut } from "../../Helpers";
import { alertError, alertToast } from "../../Helpers/Alerts";
import { CardAddress } from "./Components";
import { SelectEstados, SelectMunicipios } from "../../Components/Territorio";


export const AddressForm = () => {

  const {id} = useParams()

  const navigate = useNavigate();

  const { formState, onInputChange, onResetForm } = useForm({
    name: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
    instructions: ""
  });

  useEffect( () => {
    
    try {
      
      if (id !== '' && id !== undefined && id !== null) {
        getDataByDefault(id)
      }
      
    } catch (e) {
      console.error('error', e)
    }

  }, [id] )

  const getDataByDefault = (id) => {
    prefetchData(`shipping_addresses/`, {"id": id})
    .then( (data)=> {
      data = data?.results[0]

      let edicion = {}
      
      if (typeof data?.city_municipaly == 'object') {
        edicion = {
          city_municipaly: data?.city_municipaly,
          state_city: data?.state_city 
        }
      }

      onResetForm({
        name: data?.name,
        address: data?.address,
        city: data?.city,
        state: data?.state,
        zipcode: data?.zipcode,
        phone: data?.phone,
        instructions: data?.instructions,
        ...edicion
      })
    })
  }

  const handleAddAddress = (e) => {
    e.preventDefault();

    try {
      const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));

      const decoded = jwtDecode(token);
  
      const formData = new FormData();
      formData.append("user", decoded?.user_id);
      formData.append("name", formState.name);
      formData.append("address", formState.address);
      formData.append("city", JSON.parse(formState.city)?.id);
      formData.append("zipcode", formState.zipcode);
      formData.append("phone", formState.phone);
      formData.append("instructions", formState.instructions)
      formData.append("status", true);
  
      requestPost("shipping_addresses/", formData)
        .then((data) => {
          if (data) {
            alertToast("Dirección agregada correctamente", "success");
            onResetForm();
          }
        })
        .catch((error) => {
          alertError("Error al agregar dirección", "Por favor, intenta de nuevo");
        });
    } catch (error) {
      alertError("Error al agregar dirección", "Por favor, intenta de nuevo");
    }
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validación básica
    if (!formState.name || !formState.address || !formState.city) {
      alertError("Campos incompletos", "Por favor, completa todos los campos requeridos.");
      return;
    }

    try {
      const token = JSON.parse(localStorage.getItem("user_store_ecommerce"));
      const decoded = jwtDecode(token);
      const userId = decoded?.user_id;
      const cityId = JSON.parse(formState.city)?.id;

      const formData = createFormData({
        user: userId,
        name: formState.name,
        address: formState.address,
        zipcode: formState.zipcode,
        city: cityId || formState.city_municipaly?.id,
        phone: formState.phone,
        instructions: formState.instructions,
        status: true,
      });

      let response;
      if (id) {
        console.log("Actualizando dirección existente...");
        // Actualizar dirección existente
        response = await requestPatch(`shipping_addresses/${id}/`, formData);
        console.log(response);
        alertToast("Dirección actualizada correctamente", "success");

      } else {
        // Crear nueva dirección
        response = await requestPost("shipping_addresses/", formData);
        console.log(response);
        alertToast("Dirección agregada correctamente", "success");
      }

      if (response) {
        onResetForm({});
        navigate("/profile/address");
      }
    } catch (error) {
      console.error("Error al guardar los datos:", error);
      alertError("Error al guardar dirección", "Por favor, intenta de nuevo.");
    }
  };

  return (
    <div className="container">
      <h3 className="text-center mt-3"> {id ? 'Actualizar dirección' : 'Agregar dirección'} </h3>
      <div className="row mt-2">
        <div className="col-12 col-md-6 order-md-0">
          <form>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Nombre personal
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formState.name}
                onChange={onInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="address" className="form-label">
                Dirección
              </label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                value={formState.address}
                onChange={onInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="state" className="form-label">
                Estado
              </label>
              <SelectEstados 
                valueSelected={formState?.state_city?.id} 
                onChange={onInputChange}   
              />
            </div>
            <div className="mb-3">
              <label htmlFor="city" className="form-label">
                Ciudad
              </label>
              <SelectMunicipios
                valueSeletedState={formState?.state_city?.id} 
                valueSelected={formState?.city_municipaly?.id} 
                estadoId={JSON.parse(formState.state || '{}')?.id} 
                onChange={onInputChange} 
              />
            </div>
            <div className=" row row-cols-md-2">
              <div className="mb-3">
                <label htmlFor="zipcode" className="form-label">
                  Código postal
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="zipcode"
                  name="zipcode"
                  value={formState.zipcode}
                  onChange={onInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Teléfono
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={formState.phone}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="instructions" className="form-label">
                  Información adicional
                </label>
                <textarea
                  className="form-control"
                  id="instructions"
                  name="instructions"
                  onChange={onInputChange}
                  value={formState.instructions}
                >{formState.instructions}</textarea>
            </div>

            <div className="d-flex justify-content-end mt-3">
              <button className="btn btn-primary" onClick={handleSubmit}>
                Guardar dirección
              </button>
            </div>
          </form>
        </div>

        <div className="col-12 col-md-6 order-0">
          <div className=" d-flex flex-wrap m-auto w-100 h-100 justify-content-center align-content-center">
            <CardAddress
              name={formState.name || "..."}
              address={formState.address || "..."}
              city={JSON.parse(formState.city || '{}')?.nombre || formState?.city_municipaly?.nombre || "..."}
              state={JSON.parse(formState.state || '{}')?.nombre || formState?.state_city?.nombre || "..."}
              zipcode={formState.zipcode || "..."}
              phone={formState.phone || "..."}
              instructions={formState.instructions || "..."}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
