import axios from "axios";
import { baseUrl } from "../shared/baseUrl";
import { alertError } from "./Alerts";

export const requestPost = async (serviceName, formData) => {
    try {
        // Validar parámetros
        if (!serviceName) throw new Error("Service name is missing");
        if (!formData) throw new Error("Form data is missing");

        // Configurar headers de autorización
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //Authorization: `Bearer ${token}`
        };

        // Obtener y verificar token de autenticación
        const token = JSON.parse(localStorage.getItem('user_store_ecommerce'));
        if (!token) {
            headers.Authorization = '';
        } else {
            headers.Authorization = `Bearer ${token}`;
        }

        // Realizar la petición POST con axios
        const responsePost = await axios.post(baseUrl.concat(serviceName), formData, { headers });

        // Verificar respuesta y retornar datos o lanzar error
        if (responsePost.data === '0') {
            throw new Error("Unexpected response: '0'");
        }

        // Si el token ha expirado, redirigir a la página de inicio de sesión
        // if (responsePost.data === 'Token has expired') {
        //     alertError('Sesión expirada', 'Por favor, inicia sesión de nuevo');
        //     window.location.href = '/login';
        // }
        
        return responsePost.data;

    } catch (error) {
        if (error.response) {
            // Errores del servidor (HTTP)
            // console.error(`HTTP Error ${error.response.status}: ${error.response.statusText}`);
            console.error("Response data:", error.response.data);

            // Si el token ha expirado, redirigir a la página de inicio de sesión
            if (error.response.status === 400 && error.response.data.error === 'El token es inválido o ha expirado') {
                localStorage.removeItem('user_store_ecommerce');
                alertError("Sesión expirada", "Por favor, inicia sesión de nuevo");
                window.location.href = '/my-account';
            }
            // alertError(`Error ${error.response.status}: ${error.response.statusText}`);
            throw new Error(`Request failed with status ${error.response.status}`);
        } else if (error.request) {
            // Errores de red (sin respuesta del servidor)
            console.error("Network error: No response received");
            alertError("Error de red: Por favor, verifica tu conexión");
            throw new Error("Network error: Please check your connection");
        } else {
            // Otros errores (configuración o código)
            console.error("Request error:", error.message);
            throw error; // Propaga el error original
        }
    }
};
