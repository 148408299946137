import React from 'react'
import { useNavigate } from 'react-router-dom'

export const NotFound = ({ 
    icon = "🤠", 
    title = "No hay datos disponibles", 
    message = "Estamos trabajando para ofrecerte más contenido. ¡Vuelve pronto!",
    navigate = false,
    path = '/',
    navigateText = ''
}) => {

    const navigateTo = useNavigate();

    const handleNavigate = () => {
        navigateTo(path)
    }

    return (
        <div className='d-flex align-items-center justify-content-center h-100 m-0'>
        <div className="text-center p-3">
            <div className="fs-3">{icon}</div>
            <h2>{title}</h2>
            <p>{message}</p>
            {
                navigate && (
                    <button 
                        className="btn btn-primary"
                        onClick={handleNavigate}
                    >
                        Ir a {navigateText}
                    </button>
                )
            }
        </div>
    </div>
    )
}
